import React, {useState, useEffect, useRef} from "react";
import {TiArrowSortedDown} from "react-icons/ti";
import "../components/css/VideoAction.css";

function VideoActionOptions ({
    chooseVideoAction,
    compilationExists,
    toCompilation,
    createNewCompilation,
    selectedVideos,
    clearSelectedList,
}) {

    const actionOptionsRef = useRef(null);

    const [actionDropdown, setActionDropdown] = useState(false);

    const handleDropdown = () => {
        setActionDropdown(!actionDropdown);
    }

    const addToCompilation = () => {
        toCompilation();
        handleDropdown();
        clearSelectedList();
    }

    const createCompilation = () => {
        createNewCompilation();
        handleDropdown();
    }

    // useEffect(() => {
    //     clearSelectedList();
    // }, [pathSection]);

    const selectionNotEmpty = selectedVideos?.size !== 0;

    const actionOptions = (
        <div className="action-dropdown">
            <div className="dropdown-cont" ref={actionOptionsRef}>
                <button
                    disabled={!selectionNotEmpty}
                    className="action-title"
                    onClick={handleDropdown}>
                    <p>Actions</p>
                    <TiArrowSortedDown/>
                </button>
                {actionDropdown && (
                    <div className="action-options-cont">
                        <button onClick={() => chooseVideoAction("download")}>
                            Download
                        </button>
                        <button disabled={!compilationExists} onClick={() => addToCompilation()}>
                            Add to compilation
                        </button>
                        <button disabled={compilationExists} onClick={() => createCompilation()}>
                            New compilation
                        </button>
                        {/*<button onClick={() => chooseVideoAction("voting")}>*/}
                        {/*    To voting ballot*/}
                        {/*</button>*/}
                        {/* TODO change all unlist and list names to publish and unpublish */}
                        <button onClick={() => chooseVideoAction("list")}>
                            Publish selected
                        </button>
                        <button onClick={() => chooseVideoAction("unList")}>
                            Unpublish selected
                        </button>
                        {/* <button onClick={() => chooseVideoAction("feature")}>
                            Feature selected
                        </button>
                        <button onClick={() => chooseVideoAction("unFeature")}>
                            Unfeature selected
                        </button> */}
                    </div>
                )}
            </div>
        </div>
    );

    const clearSelected = (
        <button
            disabled={!selectionNotEmpty}
            className={selectedVideos?.size > 0? "action-clear": "action-clear-none"}
            onClick={clearSelectedList}>
            Clear ({selectionNotEmpty? (`${selectedVideos?.size} selected`) : ("None selected")})
        </button>
    )
    
    //TODO reuseable click outside
    useEffect(() => {
        const clickOutside = (event) => {
            if (actionOptionsRef.current && !actionOptionsRef.current.contains(event.target)){
                setActionDropdown(false);
            }
        };
        document.addEventListener("mousedown", clickOutside);
        return () => document.removeEventListener("mousedown", clickOutside);
    });

    return (
        <div className="action-menu">
            {actionOptions}
            {clearSelected}
        </div>
    )
}

export default VideoActionOptions;