import React from "react";
import "./Layout.css";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import {useLoginSession} from "../../stores/loginSession";
import Login from "../Login/Login";
import LoadingScreen from "../../components/LoadingScreen";
import Page from "../../components/Page";
import {Helmet} from "react-helmet-async";
import Config from "../../utility/Config";

export default function Layout () {
    const {user, token} = useLoginSession();
    const isLoading = !user && token;
    const showLogin = !user && !token;

    return (
        <div className="root-container">
            <Helmet defaultTitle={Config.meta.title} titleTemplate={Config.meta.title + " | %s"}>
                <meta name="description" content={Config.meta.description} />
                <meta name="keywords" content={Config.meta.keywords} />
            </Helmet>
            <Header />
            <div className="main-container">
                {isLoading && (
                    <Page centered>
                        <LoadingScreen />
                    </Page>
                )}
                {showLogin && (
                    <Login />
                )}
                {(!showLogin && !isLoading) && (
                    <Outlet />
                )}
            </div>
        </div>
    );
}