import React from "react";
import Modal from "react-modal";
import { useFeedbackMessage } from "../stores/FeedbackMessage";
import "./css/ModalStyling.css";

Modal.setAppElement("#root");

function CustomModal ({
    children,
    isOpen,
    className=null,
    style=null,
    onRequestClose,
    shouldCloseOnOverlayClick=true
}) {

    const {feedbackType, feedbackMessage} = useFeedbackMessage();

    if (className) className += " custom-modal";
    else className = "custom-modal";

    return (
        <Modal 
            isOpen={isOpen}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            onRequestClose={onRequestClose}
            className={className}
            style={{content: style}}
            overlayClassName="custom-modal-overlay"
            >
            {children}
            {feedbackType && feedbackMessage}
        </Modal>
    );
}

export default CustomModal;
