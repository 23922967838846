import React, {useState, useEffect} from "react";
import VideoPlayer from "../../../components/VideoPlayer/VideoPlayer";
import { VideoPlayerProvider } from "../../../components/VideoPlayer/VideoPlayerProvider";
import "./StreamDetails.css";

function StreamVideo ({ singleStream }) {

    const [streamRefreshed, setStreamRefreshed] = useState(false)

    useEffect(() => {
        const clearRefreshStream = setInterval(()=> {
            if (streamRefreshed) setStreamRefreshed(false)
        }, 3000)
        return () => clearInterval(clearRefreshStream);
    }, [streamRefreshed])

    const playlist = singleStream?.playlist
    const hasVideo = playlist && !playlist.is_placeholder
    const videoUrl = playlist?.is_live ? playlist.video_url.replace("/live/", "/live/event/") : playlist.video_url

    return (
        <div className="data-info-cont">
            <div className="data-info-head-title">
                Video
            </div>
            {hasVideo? (
                <div className="stream-video">
                    <VideoPlayerProvider playlist={playlist} url={videoUrl} autoPlay={playlist?.is_live}>
                        <VideoPlayer  />
                    </VideoPlayerProvider>
                </div>
            ) : (
                <div className="stream-no-game">No videos yet</div>
            )}
        </div>
    )
}

export default StreamVideo