import React, {createContext, useContext, useEffect, useState} from "react";
import Backend, {getFullPath, infrequentCacheConf, useBackend} from "../utility/Backend";
import Config from "../utility/Config";

function createSession (conf={}) {
    const defaults = {
        token: null,
        user: null,
        username: null,
        error: null,
        loginIsLoading: false,
        mamConfig: {},
    }
    return {...defaults, ...conf}
}

const localstorageKey = "f-session";

function loadSessionFromLocalstorage () {
    const sessionInfo = localStorage.getItem(localstorageKey);
    if (!sessionInfo) return createSession();
    const [username, token] = sessionInfo.split(":");
    return createSession({token, username, loginIsLoading: true});
}

const LoginSessionContext = createContext(null);

function LoginSessionProvider ({children}) {
    const [state, setState] = useState(loadSessionFromLocalstorage);

    useEffect(() => {
        // Listen for storage events from other tabs, logging in and out
        // NOTE: This does not execute when the event stems from *this* tab
        const handleStorageEvent = (e) => {
            if (e.key !== localstorageKey) return
            const newSession = loadSessionFromLocalstorage()
            console.log("Storage changed from another tab:", newSession)
            setState(newSession);
        }
        window.addEventListener("storage", handleStorageEvent)
        return () => window.removeEventListener("storage", handleStorageEvent)
    }, [])

    async function setUser (user, token) {
        const username = user.username || user.fotbollkonto_unique_name;

        // Verify that this user actually has MAM access
        const {data, error} = await Backend.get("/mam_config", {access_token: token});
        if (error) {
            setState(createSession({error: `The account "${username}" does not have sufficient access`}));
            return false;
        }

        localStorage.setItem(localstorageKey, `${username}:${token}`);
        setState(createSession({user, username, token, mamConfig: data}));
        return true
    }

    const {token} = state
    const validatePath = token ? "/user/me" : null
    const {data, error} = useBackend(validatePath, {access_token: token}, infrequentCacheConf)

    useEffect(() => {
        if (!token) return
        if (error) {
            localStorage.removeItem(localstorageKey);
            setState(createSession({error: error?.message || error}));
        } else if (data) {
            setUser(data, token);
        }
    }, [data, error])

    function parseError (error, response) {
        let errorMessage = error
        if (response.status === 401) {
            errorMessage = "Login session expired"
        }
        if (response.status === 403 || response.status === 400) {
            errorMessage = "Incorrect credentials";
        }
        return errorMessage
    }

    const context = {
        ...state,
        logIn: (username, password) => {
            const body = JSON.stringify({"username": username, "password": password});
            Backend.post("/user/authorize", body)
                .then(({data, error, response}) => {
                    if (error) {
                        setState(createSession({error: parseError(error, response)}));
                    } else {
                        setUser(data, data.access_token);
                    }
                });
        },
        ssoLogin: async (code, refreshToken) => {
            if (code === null && refreshToken === null) {
                console.warn("Login callback called without a code or refresh token")
                return
            }
            const query = {}
            if (code !== null) query.code = code
            if (refreshToken !== null) query.refresh_token = refreshToken
            setState(createSession({loginIsLoading: true}));
            const {data, error, response} = await Backend.get("/user/fotbollkonto/validate", query)
            if (error) {
                localStorage.removeItem(localstorageKey);
                setState(createSession({error: parseError(error, response)}));
            } else {
                const success = await setUser(data.user, data.access_token);
                // TODO This only logs the user out of Forzify
                //  in order to support SSO, we need to properly visit the site with ?redirect=true.
                //  that would be confusing for users, so we need to make an intermediary step where you're logged in
                //  but don't have sufficient access and instead just an error and a logout/switch account button.
                if (!success)
                    await Backend.post("/user/logout", undefined, {access_token: data.access_token})
            }
        },
        logOut: () => {
            localStorage.removeItem(localstorageKey);
            setState(createSession());
            if (state?.user?.fotbollkonto) {
                // Actually navigate to the sso
                window.location = Config.getBackend() + getFullPath("/user/logout", {
                    access_token: state.token,
                    redirect: true,
                })
            } else {
                Backend.get("/user/logout", {access_token: state.token})
            }
        },
        allowUploading: !!(state.mamConfig.allow_uploading),
        allowSchedulingStreams: !!(state.mamConfig.allow_schedule_live_stream),
        allowEditingTeams: !!(state.mamConfig.allow_editing_teams),
        allowEditingUsergroups: !!(state.mamConfig.allow_editing_usergroups),
        allowVideoExport: !!(state.mamConfig.allow_video_export),
        allowPlaylistEditPermissions: !!(state.mamConfig.allow_playlist_edit_permissions),
        allowAddingPayment: !!(state.mamConfig.allow_adding_payment)
    }

    return (
        <LoginSessionContext.Provider value={context}>
            {children}
        </LoginSessionContext.Provider>
    )
}

function useLoginSession () {
    const context = useContext(LoginSessionContext)
    if (context === undefined) {
        throw new Error("useLoginSession must only be used inside of LoginSessionProvider");
    }
    return context;
}

export {LoginSessionProvider, useLoginSession}