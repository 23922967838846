import React, {useState} from "react";
import EditTeam from "./EditTeam";
import {useBackend} from "../../../utility/Backend";
import "./TeamDetails.css";
import { FiEdit3 } from "react-icons/fi";

function TeamDetails ({ refresh, teamId, activeLeagueTeams }) {

    const apiQuery = {"show_players" : null}

    const {data} = useBackend(
        "/team/" + teamId, 
        apiQuery,
        {include_featured_flag: true},
        // {fallbackData: fallback} //TODO
    );

    const [isEditTeamOpen, setIsEditTeamOpen] = useState(false);

    if (!data) return null;

    const openEditTeam = () => {
        setIsEditTeamOpen(true);
    }

    const closeEditTeam = () => {
        setIsEditTeamOpen(false);
    }

    const urlBaseName = window.location.pathname.split("/")[1];
    const teamInActiveLeague = activeLeagueTeams.teams.map((item) => item.id).includes(parseInt(teamId));
    
    let teamMatchesUrl = `/library/matches?team=${teamId}`;
    let teamGamesUrl = `/library/events?team=${teamId}`;

    if (!teamInActiveLeague){
        if (urlBaseName === "allsvenskan") {
            teamMatchesUrl = "/superettan" + teamMatchesUrl;
            teamGamesUrl = "/superettan" + teamGamesUrl;
        } 
        if (urlBaseName === "superettan") {
            teamMatchesUrl = "/allsvenskan" + teamMatchesUrl;
            teamGamesUrl = "/allsvenskan" + teamGamesUrl;
        } 
    }
    else {
        teamMatchesUrl = `/${urlBaseName}${teamMatchesUrl}`;
        teamGamesUrl = `/${urlBaseName}${teamGamesUrl}`;
    };

    const teamDetailsInfo = (
        <div className="data-info-cont">
            <div className="data-info-head-title">Team details</div>
            <div className="team-details-info-cont">
                <div className="logo-and-goto-cont">
                    <div className="tdi-logo-cont">
                        <img src={data.logo_url} alt="logo" className="tdi-logo"/>
                    </div>
                    <div className="goto-btn-cont">
                        <a href={teamMatchesUrl}  className="goto-link">
                            All matches of this team
                        </a>
                        <a href={teamGamesUrl} className="goto-link">
                            All events of this team
                        </a>
                    </div>
                </div>
                <div className="tdi-cont">
                    <div className="data-info">
                        <div className="data-info-title">Name</div>
                        <div className="data-info-value">{data.name}</div>
                    </div>
                    <div className="data-info">
                        <div className="data-info-title">Coach</div>
                        <div className="data-info-value">{data.coach}</div>
                    </div>
                    <div className="data-info">
                        <div className="data-info-title">Short name</div>
                        <div className="data-info-value">{data.short_name}</div>
                    </div>
                    <div className="data-info">
                        <div className="data-info-title">Import id</div>
                        <div className="data-info-value">{data.import_id}</div>
                    </div>
                    <div className="confirm-cancel-btn-cont">
                        <button onClick={openEditTeam}>
                            <FiEdit3 className="icon-in-btn"/>
                            Edit team
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            {teamDetailsInfo}
            {isEditTeamOpen && (
                <EditTeam 
                    refresh={refresh}
                    team={data}
                    closeEditTeam={closeEditTeam}/>
            )}
        </>
    )
}

export default TeamDetails;