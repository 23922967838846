import React, { useState, useEffect } from "react";
import { useFeedbackMessage } from "../../stores/FeedbackMessage";
import { RiVideoUploadLine } from "react-icons/ri";
import classNames from "classnames";
import "./UploadDragAndDrop.css";

function UploadDragAndDrop ({ 
    chosenFile, 
    onFileChange,
    onUrlChange,
    disableInput=false,
    smallSize=false
}) {

    const {showFeedback} = useFeedbackMessage();

    const [isDropping, setIsDropping] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const onAreaOver = (e) => e.preventDefault();
    const onAreaEnter = () => setIsDropping(true);
    const onAreaLeave = () => setIsDropping(false);
    
    let fileTargetUrl = null;
    const onSelectFile = (e) => {
        if (!e.target.files[0]){
            return null;
        }
        onFileChange(e.target.files[0]);
        fileTargetUrl = URL.createObjectURL(e.target.files[0])
        onUrlChange(fileTargetUrl);
    }

    useEffect(() => {
        if (fileTargetUrl && !chosenFile) URL.revokeObjectURL(fileTargetUrl);
    }, [fileTargetUrl, chosenFile]);

    useEffect(() => {
        if (chosenFile) setErrorMsg("");
    }, [chosenFile]);

    const onFileDrop = (e) => {
        e.preventDefault();
        
        if (e.dataTransfer.files.length > 1){
            setErrorMsg("Can only upload one file at a time");
            setIsDropping(false);
            return;
        }

        const fileExt = e.dataTransfer.files[0].name.split(".").pop().toLowerCase();
        const validFileTypes = ["mp4", "avi", "mpeg", "mpg", "ismv", "mov", "mkv", "webm", "flv", "ts"];
        
        if (!validFileTypes.includes(fileExt)) {
            setErrorMsg("Unsupported video file extension ." + fileExt);
            setIsDropping(false);
            return;
        }

        onFileChange(e.dataTransfer.files[0])
        onUrlChange(URL.createObjectURL(e.dataTransfer.files[0]));
        setIsDropping(false);
        showFeedback();
    };

    return (
        <div
            onDragOver={onAreaOver}
            onDrop={onFileDrop}
            onDragEnter={onAreaEnter}
            onDragLeave={onAreaLeave}
            className={classNames("drag-and-drop", {
                "is-dropping": isDropping,
                "small": smallSize
            })}
            >
            <RiVideoUploadLine className="upload-icon" />
            <div className="drag-and-drop-text">{chosenFile? "Drag file here to change file" : 
                    "Drag file here to upload" }</div>
            <div className="drag-and-drop-text">or</div>
            {!disableInput && (
                <input 
                    type="file" 
                    name="file" 
                    id="file" 
                    onChange={onSelectFile}
                    accept="video/mp4,video/x-m4v,video/*"/>
            )}
            <label htmlFor="file" 
                className="input-label">
                Choose file
            </label>
            {errorMsg && <div className="dnd-error-msg">
                {errorMsg}
                <br />
                {errorMsg.includes("Unsupported") && "Valid extensions: mp4, avi, mpeg, mpg, ismv, mov, mkv, webm, flv, ts"}
            </div>}
        </div>
    )
}

export default UploadDragAndDrop;
