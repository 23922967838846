import React, {useEffect, useState} from "react"
import { useBackend } from "../utility/Backend";
import { useCompilation } from "../stores/compilation";
import { useLoginSession } from "../stores/loginSession";
import Backend from "../utility/Backend";
import { useParams } from "react-router-dom";
import { useFeedbackMessage } from "../stores/FeedbackMessage";
import { showErrorMessage, useUpdateSearchParams } from "../utility/Utilities";
import ConfirmModal from "./ConfirmModal";
import CustomModal from "./CustomModal";
// import classNames from "classnames";
import {MdListAlt} from "react-icons/md";
import {CgUnavailable} from "react-icons/cg";
// import {FiSquare, FiCheckSquare} from "react-icons/fi";
// import {MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox} from "react-icons/md";
import "./css/GenerateHighlights.css";

function GenerateHighlights ({game}) {

    const [customHighlights, setCustomHighlights] = useState(false)
    const [durationMinute, setDurationMinute] = useState(2)
    const [durationSecond, setDurationSecond] = useState(0)
    const [highlightsEvents, setHighlightsEvents] = useState([])
    const [warningModal, setWarningModal] = useState(null)
    const [highlightsVideo, setHighlightsVideo] = useState(null)
    const [hasError, setHasError] = useState(false)

    const {showFeedback} = useFeedbackMessage();
    const {initializeCompilation} = useCompilation();
    const [, updateSearchParams] = useUpdateSearchParams();
    const {compilationExists, playlistId: compilationId} = useCompilation();

    const {token} = useLoginSession()
    const query = {access_token: token}
    const {gameId} = useParams()

    const { data: eventsData } = useBackend("/game/" + game.id + "/events", { count: "150" }, {})
    const gameNotStartedOrNoEvents = (new Date() < new Date(game.start_time)) && eventsData?.events?.length === 0

    const totalDuration = (durationMinute * 60) + parseInt(durationSecond)
    const exceedMaxDuration = totalDuration > 900

    const initGenerateHighlights = async () => {
        let generateHighlightsObject = {max_duration: totalDuration}
        const {data} = await Backend.get(`/suggest/game/${gameId}/highlights`, generateHighlightsObject, query)
        setHighlightsVideo(data)
    }
    
    useEffect(() => {
        if (!highlightsVideo) return
        else {
            initializeCompilation(highlightsVideo, highlightsVideo.id, true);
            updateSearchParams({"editing": "playlist_" + highlightsVideo.id, "edit_metadata": true});
            showFeedback("success", "New compilation created successfully!");
            setHighlightsVideo(null)
        }
    }, [highlightsVideo])

    useEffect(() => {
        if (!customHighlights) setHasError(false)
        if (customHighlights && highlightsEvents.length !== 0) setHasError(false)
    }, [customHighlights, highlightsEvents])

    useEffect(() => {
        if (exceedMaxDuration) setHasError(true)
        else setHasError(false)
    }, [durationMinute, durationSecond])

    const toExistingVideoEdit = () => {
        updateSearchParams("editing", "playlist_" + compilationId);
        setWarningModal(null)
    }

    const generateHighlightsConfirmation = () => {
        if (gameNotStartedOrNoEvents) setWarningModal("unavailable")
        else if (compilationExists) setWarningModal("compilation exists")
        else setWarningModal("generate highlights")
    }

    const resetGenerateHighlights = () => {
        setCustomHighlights(false)
        setDurationMinute(2)
        setDurationSecond(0)
        setHighlightsEvents([])
        setWarningModal(null)
        setHasError(false)
    }

    const handleGenerateHighlights = () => {
        if (customHighlights && highlightsEvents.length === 0) {
            setHasError(true)
        } else {
            initGenerateHighlights()
            resetGenerateHighlights()
        }
    }
   
    // const handleSelectHighlightsEvent = (event) => {
    //     if (highlightsEvents.includes(event)) {
    //         const filteredEvents = highlightsEvents.filter((e) => e !== event)
    //         setHighlightsEvents(filteredEvents)
    //     } else {
    //         setHighlightsEvents([...highlightsEvents, event])
    //     }
    // }

    // const selectCustomHighlights = () => {
    //     if (customHighlights) return
    //     else {
    //         setHighlightsEvents(["goal", "shot on goal", "yellow card", "red card", "penalty"])
    //         setCustomHighlights(true)
    //     }
    // }

    // const unSelectCustomHighlights = () => {
    //     setHighlightsEvents([])
    //     setMaximumDuration("")
    //     setCustomHighlights(false)
    // }

    const maximumDurationOptions = (
        <div className="maximum-duration-cont">
            <div className="input-container">
                <label className="input-title">Maximum duration</label>
                <div className="maximum-duration">
                    <input 
                        type="number"
                        min="0"
                        max="15"
                        value={durationMinute}
                        onChange={(e) => setDurationMinute(e.target.value)}/>
                    <div>Minutes</div>
                    <input 
                        type="number"
                        min="0"
                        max="59"
                        value={durationSecond}
                        onChange={(e) => setDurationSecond(e.target.value)}/>
                    <div>Seconds</div>
                </div>
                <div className="empty-event-warning">
                    {showErrorMessage("Maximum duration is 15 minutes", exceedMaxDuration)}
                </div>
            </div>
        </div>
    )

    // const customHighlightsOptions = (
    //     <div className="custom-highlights-options-cont">
    //         {maximumDurationOptions}
    //         <div className="highlights-event-cont">
    //             <div className="highlights-event-title">Events :</div>
    //             <div onClick={() => handleSelectHighlightsEvent("goal")} 
    //                 className={classNames("highlights-event-single", {"active": highlightsEvents.includes("goal")})}
    //                 >
    //                 {highlightsEvents.includes("goal") ? 
    //                 (<MdOutlineCheckBox/>) : (<MdOutlineCheckBoxOutlineBlank/>)}
    //                 Goal
    //             </div>
    //             <div onClick={() => handleSelectHighlightsEvent("shot on goal")} 
    //                 className={classNames("highlights-event-single", {"active": highlightsEvents.includes("shot on goal")})}
    //                 >
    //                 {highlightsEvents.includes("shot on goal") ? 
    //                 (<MdOutlineCheckBox/>) : (<MdOutlineCheckBoxOutlineBlank/>)}
    //                 Shot on goal
    //             </div>
    //             <div onClick={() => handleSelectHighlightsEvent("yellow card")} 
    //                 className={classNames("highlights-event-single", {"active": highlightsEvents.includes("yellow card")})}
    //                 >
    //                 {highlightsEvents.includes("yellow card") ? 
    //                 (<MdOutlineCheckBox/>) : (<MdOutlineCheckBoxOutlineBlank/>)}
    //                 Yellow card
    //             </div>
    //             <div onClick={() => handleSelectHighlightsEvent("red card")} 
    //                 className={classNames("highlights-event-single", {"active": highlightsEvents.includes("red card")})}
    //                 >
    //                 {highlightsEvents.includes("red card") ? 
    //                 (<MdOutlineCheckBox/>) : (<MdOutlineCheckBoxOutlineBlank/>)}
    //                 Red card
    //             </div>
    //             <div onClick={() => handleSelectHighlightsEvent("penalty")} 
    //                 className={classNames("highlights-event-single", {"active": highlightsEvents.includes("penalty")})}
    //                 >
    //                 {highlightsEvents.includes("penalty") ? 
    //                 (<MdOutlineCheckBox/>) : (<MdOutlineCheckBoxOutlineBlank/>)}
    //                 Penalty
    //             </div>
    //             <div onClick={() => handleSelectHighlightsEvent("offside")} 
    //                 className={classNames("highlights-event-single", {"active": highlightsEvents.includes("offside")})}
    //                 >
    //                 {highlightsEvents.includes("offside") ? 
    //                 (<MdOutlineCheckBox/>) : (<MdOutlineCheckBoxOutlineBlank/>)}
    //                 Offside
    //             </div>
    //             <div onClick={() => handleSelectHighlightsEvent("medical treatment")} 
    //                 className={classNames("highlights-event-single", {"active": highlightsEvents.includes("medical treatment")})}
    //                 >
    //                 {highlightsEvents.includes("medical treatment") ? 
    //                 (<MdOutlineCheckBox/>) : (<MdOutlineCheckBoxOutlineBlank/>)}
    //                 Medical treatment
    //             </div>
    //             <div onClick={() => handleSelectHighlightsEvent("substitution")} 
    //                 className={classNames("highlights-event-single", {"active": highlightsEvents.includes("substitution")})}
    //                 >
    //                 {highlightsEvents.includes("substitution") ? 
    //                 (<MdOutlineCheckBox/>) : (<MdOutlineCheckBoxOutlineBlank/>)}
    //                 Substitution
    //             </div>
    //         </div>
    //         <div className="empty-event-warning">
    //             {hasError && showErrorMessage("please select minimum 1 event", (customHighlights && highlightsEvents.length === 0))}
    //         </div>
    //     </div>
    // )

    const generateHighlightsModal = (
        <CustomModal isOpen onRequestClose={resetGenerateHighlights} className="small-short">
            <div className="generate-highlights-cont">
                <div className="generate-highlights-title">Generate match highlights</div>
                {maximumDurationOptions}
                {/* <form className="generate-highlights-form">
                    <div className="generate-highlights-title">Generate highlights</div>
                    <div
                        onClick={unSelectCustomHighlights} 
                        className={classNames("option-checkbox", {"active": !customHighlights})}>
                        {!customHighlights? 
                            <FiCheckSquare className="check-box-icon"/> : 
                            <FiSquare className="check-box-icon"/>}
                        <div className="option-box-title">
                            Default
                            <div className="option-extra-message"><BsInfoCircle className="info-icon"/>Highlights selected by video rating</div>
                        </div>
                    </div>
                    <div
                        onClick={selectCustomHighlights}
                        className={classNames("option-checkbox", {"active": customHighlights})}
                        >
                        <div className="custom-highlights-cont">
                            <div className="custom-highlights-title">
                                {customHighlights?
                                    <FiCheckSquare className="check-box-icon"/> :
                                    <FiSquare className="check-box-icon"/>}
                                <div className="option-box-title">
                                    Custom
                                    <div className="option-extra-message"><BsInfoCircle className="info-icon"/>Selectable events and duration</div>
                                </div>
                            </div>
                            {customHighlights && customHighlightsOptions}
                        </div>
                    </div>
                </form> */}
                <div className="confirm-cancel-btn-cont full">
                    <button className="green-hover-btn" disabled={hasError} onClick={handleGenerateHighlights}>
                        Generate
                    </button>
                    <button onClick={resetGenerateHighlights}>
                        Cancel
                    </button>
                </div>
            </div>
        </CustomModal>
    )

    const compilationExistsModal = (
        <ConfirmModal 
            isOpen
            onClose={() => setWarningModal(null)}
            cancelText="Close"
            noConfirmBtn
            >
            <div className="confirm-icon-message">
                <div className="confirm-icon"><MdListAlt/></div>
                <div className="confirm-title">You have existing new compilation or highlights. Save or delete the existing to create a new one.</div>
                <span onClick={toExistingVideoEdit} className="to-existing-video-edit">Go to existing compilation / highlights</span>
            </div>
        </ConfirmModal>
    );

    const highlightsNotAvailableModal = (
        <ConfirmModal 
            isOpen
            onClose={() => setWarningModal(null)}
            cancelText="Close"
            noConfirmBtn
            >
            <div className="confirm-icon-message">
                <div className="confirm-icon"><CgUnavailable/></div>
                <div className="confirm-title">Match is not started or has no events yet</div>
            </div>
        </ConfirmModal>
    );

    return (
        <>
            <button type="button" className="generate-highlights-btn green-hover-btn" onClick={generateHighlightsConfirmation}>
                Generate match highlights
            </button>
            {warningModal === "generate highlights" && generateHighlightsModal}
            {warningModal === "compilation exists" && compilationExistsModal}
            {warningModal === "unavailable" && highlightsNotAvailableModal}
        </>
    )
}

export default GenerateHighlights