import React, {useEffect, useState} from "react";
import {useLoginSession} from "../stores/loginSession";
import { useFeedbackMessage } from "../stores/FeedbackMessage";
import Config from "../utility/Config";
import { FiDownload } from "react-icons/fi";

export default function ExportButton ({
    children,
    className="",
    button=false,
    autoHideLongDuration=true,
    filename=undefined,
    duration=undefined,
    videoUrl=undefined,
}) { 

    const isTooLong = duration > Config.maximumExportSeconds;

    const {token} = useLoginSession();
    const [disabledUrl, setDisabledUrl] = useState(null);

    const {showFeedback,} = useFeedbackMessage();

    useEffect(() => {
        if (!disabledUrl) return;
        if (disabledUrl !== videoUrl) {
            setDisabledUrl(null);
            return;
        }
        const url = disabledUrl;
        const t = setTimeout(() => {
            if (url === disabledUrl) setDisabledUrl(null);
        }, 20000);
        return () => clearTimeout(t);
    }, [disabledUrl, videoUrl]);

    // Disable export for now for longer videos
    if (isTooLong && autoHideLongDuration) return null;

    function onClick (e) {
        e.stopPropagation();

        if (disabledUrl) return;

        if (isTooLong) {
            showFeedback("warning", `Video is too long to export, maximum ${Config.maximumExportSeconds} seconds`);
            return;
        }

        // There's not really a good way to detect when a download starts/completes
        // To avoid user spamming the button we just lock it for a bit unless the url changes
        setDisabledUrl(videoUrl);

        // TODO This is a bit hacky, but the videourl reported by the backend isn't
        //      always the correct domain, and therefore might not be the one the login session is for
        const url = videoUrl
            .replace("Manifest.m3u8","50000000/" + filename + ".mp4")
            .replace(/https:\/\/[^.]*api\..*\.com\//, Config.api)

        if (Config.premiereProPlugin) {
            const pl = {
                mp4_url: url,
                id: filename,
            };
            const msg = { at: token, videos: [pl] };
            console.log("Posting:", "playlists_" + JSON.stringify(msg));
            window.top.postMessage("playlists_" + JSON.stringify(msg), "*");
            return;
        }

        // Note: I do not like exposing the access token in the DOM
        // + it's easier to control a div than an anchor
        const a = document.createElement("a");
        a.href = url + "?access_token=" + token;
        a.download = filename + ".mp4";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const isDisabled = isTooLong || disabledUrl;
    if (isDisabled) className += " disabled";

    if (button) {
        let content = children
        if (disabledUrl) content = "Exporting..."
        return (
            <button className={className}
                    disabled={isDisabled ? "disabled": undefined}
                    onClick={onClick}
                >
                <FiDownload className="icon-in-btn"/>
                {content}
            </button>
        );
    }

    return (
        <div className={className} onClick={onClick}>
            {children}
        </div>
    );

}
