import classNames from "classnames";
import React, { useState } from "react"
import { useBackend } from "../../../utility/Backend";
import { useVideoPlayer } from "../../../components/VideoPlayer/VideoPlayerProvider";
import { getTimelineClickRatio, throttled, capitalizeFirstLetter } from "../../../utility/Utilities";
import { useLoginSession } from "../../../stores/loginSession";
import MatchTimelineList from "./MatchTimelineList";
import "./FullMatchTimeline.css"
import {MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox} from "react-icons/md";

export const phases = ["start phase", "end phase", "end of game"]
function TimelineTagOptions ({ tags, selectedEvents, onSelectEvent }) {

    const matchPhases = ["start phase", "end phase", "end of game"];
    const matchTags = tags.filter(t => !matchPhases.includes(t))

    return (
        <div className="timeline-event-options-cont">
            <div className="timeline-event-options-title">Events :</div>
            <div className="timeline-events-cont">
                <div
                    onClick={() => onSelectEvent("all")}
                    className={classNames("timeline-event-option", { "active": selectedEvents.length === tags.length })}
                >
                    {selectedEvents.length === tags.length ? (
                        <MdOutlineCheckBox />
                    ) : (
                        <MdOutlineCheckBoxOutlineBlank />
                    )}
                    All
                </div>
                {matchTags.map(tag => {
                    let tagName = tag
                    if (tag === "goalkeeperevent") tagName = "goalkeeper"
                    if (tag === "shootoutpenaltyshot") tagName = "shoot out penalty shot"
                    return (
                        <div
                            key={tag}
                            onClick={() => onSelectEvent(tag)}
                            className={classNames("timeline-event-option", { "active": selectedEvents.includes(tag) })}
                        >
                            {selectedEvents.includes(tag) ? (
                                <MdOutlineCheckBox />
                            ) : (
                                <MdOutlineCheckBoxOutlineBlank />
                            )}
                            {capitalizeFirstLetter(tagName)}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export function FullMatchTimeline ({ playlist }) {

    const { token } = useLoginSession()
    const query = { access_token: token }
    const { seekTo, playing, setPlaying, videoProgress } = useVideoPlayer();

    const [selectedEvents, setSelectedEvents] = useState(["goal", "penalty"])
    
    const refreshInterval = playlist.is_live ? {
        dedupingInterval: 4900,
        refreshInterval: 5000
    } : {}

    const { data: eventsData } = useBackend("/game/" + playlist.game.id + "/events", { count: "250" }, refreshInterval);
    const { data: tagsData } = useBackend("/tag", query)

    const events = eventsData?.events || []
    const allTags = tagsData?.tags || []

    // get the start time of the playlist, and add the duration of the playlist
    // this is required to handle full match video with multiple assets
    const playlistStartTime = new Date(playlist.recording_timestamp).getTime()
    const playlistStartPlusDuration = playlistStartTime + playlist.duration_ms

    const phaseEvents = events.filter((e) => phases.includes(e.tag.action))

    const matchEvents = events
        // filter the event types (goal, shot, penalty, etc)
        .filter((event) => selectedEvents.includes(event?.playlist?.events[0].tags[0].action))
        // filter the the event that has the same asset id
        .filter((event) => playlist.master_videoasset.id === event?.playlist?.events[0].video_asset_id)
        // filter events from the start of the playlist to the duration of the playlist
        .filter((event) => {
            return new Date(event.playlist.recording_timestamp) > new Date(playlist.recording_timestamp) && new Date(event.playlist.recording_timestamp) < new Date(playlistStartPlusDuration)
        })
        // filter out events that have no team prop
        .filter((event) => !!event.tag.team)

    const filteredEvents = phaseEvents.concat(matchEvents)

    // TODO football ignored tags
    const ignoredTags = ["highlights", "throw-in", "interview", "medical treatment", "education", "match", "misc", "offside", "press conference", "promotion", "training"];
    const tags = Object.keys(allTags).filter(t => !ignoredTags.includes(t))

    const onSelectEvent = (event) => {
        if (event === "all") setSelectedEvents(tags)
        else if (!selectedEvents.includes(event)) setSelectedEvents([...selectedEvents, event])
        else {
            const filteredEvents = selectedEvents.filter((e) => e !== event)
            setSelectedEvents(filteredEvents)
        }
    }

    const timelineMouseDown = async (e) => {
        e.preventDefault();
        const target = e.target

        if (!playing && videoProgress === 0) await setPlaying(true)

        const onMouseMove = throttled((e) => {
            seekTo(getTimelineClickRatio(e, target), true)
        }, 200);

        const onMouseUp = () => {
            window.removeEventListener("mousemove", onMouseMove);
            window.removeEventListener("mouseup", onMouseUp);
        };
        onMouseMove(e);
        window.addEventListener("mousemove", onMouseMove);
        window.addEventListener("mouseup", onMouseUp);
    }

    return (
        <div className="match-timeline-main">
            <TimelineTagOptions tags={tags} selectedEvents={selectedEvents} onSelectEvent={onSelectEvent} />
            
            <div className="match-timeline-cont">
                <MatchTimelineList
                    playlist={playlist}
                    filteredEvents={filteredEvents}
                    timelineMouseDown={timelineMouseDown} />
            </div>
        </div>
    )
}