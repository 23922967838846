import React, { useState } from "react"
import CustomModal from "../../components/CustomModal"
import classNames from "classnames"
import "./UploadProgressSection.css"

export const [
    STANDBY,
    UPLOADING,
    CANCELLED,
    COMPLETED,
    FAILED,
] = [0,1,2,3,4]

function UploadProgressSection ({
    chosenFile, 
    title, 
    publishPeriod, 
    uploadStatus, 
    uploadProgress, 
    resetUpload, 
    cancelUpload
}) {

    const [cancelConfirmation, setCancelConfirmation] = useState(false)

    const handleCancelUpload = () => setCancelConfirmation(!cancelConfirmation)

    const progressWidth = (uploadProgress * 100.0).toFixed(0) + "%";

    const uploadDetailsData = (
        <div className="upload-details-data-cont">
            <div className="data-info">
                <div className="data-info-title">Filename</div>
                <div className="data-info-value">{chosenFile? chosenFile.name : null}</div>
            </div>
            <div className="data-info">
                <div className="data-info-title">Title</div>
                <div className="data-info-value">{title}</div>
            </div>
        </div>
    )

    const cancelUploadConfirmation = (
        <div className="cancel-confirmation-cont">
            <div>Are you sure to cancel the upload?</div>
            <button type="button" onClick={cancelUpload} className="red-btn">
                Yes
            </button>
            <button type="button" onClick={handleCancelUpload}>
                No
            </button>
        </div>
    )

    return (
        <CustomModal isOpen className="progress-bar">
            <div className="upload-progress-cont">
                <div className={classNames("upload-status-title", {
                    "uploading": uploadStatus === UPLOADING,
                    "completed": uploadStatus === COMPLETED,
                    "failed": uploadStatus === FAILED,
                })}>
                    {uploadStatus === UPLOADING && "Uploading..."}
                    {uploadStatus === COMPLETED && "Completed"}
                    {uploadStatus === FAILED && "Failed"}
                </div>
                {uploadDetailsData}
                <div className="upload-progress-bar">
                    <div className="progress-bar-fill" style={{width: progressWidth}} />
                    <div className={classNames("progress-bar-number", {"completed": uploadProgress === 1})}>{progressWidth}</div>
                </div>
                <button
                    type="button"
                    disabled={cancelConfirmation}
                    onClick={uploadStatus === COMPLETED? resetUpload : handleCancelUpload} 
                    className="cancel-upload-btn"
                    >
                    {uploadProgress === 1? "Close" : "Cancel upload"}
                </button>
                {cancelConfirmation && cancelUploadConfirmation}
            </div>
        </CustomModal>
    )
}

export default UploadProgressSection