import React, {useState, useRef, useEffect} from "react"
import { useCheckIsAdmin } from "../../utility/UserGroups";
import {useGetChannels} from "../../utility/UseGetChannels";
import { ClickOutside } from "../../utility/Utilities";
import classNames from "classnames";
import {FaTimes} from "react-icons/fa";
import {TiArrowSortedDown} from "react-icons/ti";

function UploadChannelSection ({selectedChannel, setSelectedChannel, autoFill=false}) {
    
    const dropdownListRef = useRef(null)
    const [,isAdmin] = useCheckIsAdmin()
    const channelsList = useGetChannels()
    const searchChannelInputRef = useRef(null)

    const [isChannelListOpen, setIsChannelListOpen] = useState(false)
    const [searchChannel, setSearchChannel] = useState("")
    const [channelIndex, setChannelIndex] = useState(null)

    ClickOutside(dropdownListRef, setIsChannelListOpen)

    useEffect(() => {
        if (isChannelListOpen && searchChannelInputRef) searchChannelInputRef.current?.focus();
    }, [isChannelListOpen])

    useEffect(() => {
        if (!isChannelListOpen) setChannelIndex(null)
    }, [isChannelListOpen])

    let channels = channelsList
    if (searchChannel) {
        channels = channels.filter((channel) => channel.name.toLowerCase().includes(searchChannel.toLowerCase()))
    }

    useEffect(() => {
        setChannelIndex(null)
    }, [channels.length])

    useEffect(() => {
        // As a convenience thing, if the user only has access to one channel (very common), autofill on load
        if (autoFill && channelsList.length === 1)
            handleChooseChannel(channelsList[0])
    }, [channelsList])

    const onKeyPress = (e) => {
        // arrow down key
        if (e.keyCode === 40) {
            if (channelIndex === channels.length-1) return
            if (channelIndex === null) setChannelIndex(0)
            else setChannelIndex (channelIndex + 1)
        }
        // arrow up key
        if (e.keyCode === 38) {
            if (channelIndex === 0) return
            else setChannelIndex (channelIndex - 1)  
        }
        // enter key
        if (e.keyCode === 13) {
            handleChooseChannel(channels[channelIndex]);
        }
        // escape key
        if (e.keyCode === 27) {
            setIsChannelListOpen(false)
        }
    }

    useEffect(() => {
        if (isChannelListOpen) window.addEventListener("keydown", onKeyPress);
        return () => {
            window.removeEventListener("keydown", onKeyPress);
        }
    })

    const handleChannelList = () => {
        setIsChannelListOpen(!isChannelListOpen)
        setSearchChannel("")
    }

    const handleChooseChannel = (channel) => {

        if (selectedChannel.includes(channel)) setSelectedChannel(selectedChannel)
        else setSelectedChannel([...selectedChannel, channel])
        
        setSearchChannel("")
        setIsChannelListOpen(false)
    }

    const removeChannel = (id) => {
        const updatedChannels = selectedChannel.filter(channel => channel.id !== id)
        setSelectedChannel(updatedChannels)
    }

    const channelSection = (
        <div className="selected-channels-cont">
            {(selectedChannel && selectedChannel.length !== 0) && (
                <div className="chosen-channel-list">
                    {selectedChannel.map((channel) => {
                        return (
                            <div key={channel.id} className="chosen-channel-single">
                                {channel.name}
                                <FaTimes onClick={() => removeChannel(channel.id)} className="remove-channel-icon"/>
                            </div>
                        )
                    })}
                </div>
            )}
            <div ref={dropdownListRef} className="upload-dropdown-cont">
                <div onClick={handleChannelList} className="upload-dropdown-title">
                    Add channel
                    <TiArrowSortedDown/>
                </div>
                {isChannelListOpen && (
                    <div className="upload-dropdown-list">
                        {isAdmin && (
                            <div className="upload-channel-search-cont">
                                <div className="upload-channel-search">Search : </div>
                                <input
                                    ref={searchChannelInputRef}
                                    type="text"
                                    onChange={(e) => setSearchChannel(e.target.value)}
                                    value={searchChannel}
                                    className=""/>
                            </div>
                        )}
                        {channels.length !== 0? (
                            <div className="upload-channel-list">
                                {channels.map((channel, idx) => {
                                    return (
                                        <div
                                            key={channel.id}
                                            onClick={() => handleChooseChannel(channel)}
                                            className={classNames("channel-single", {"active": channelIndex === idx})}
                                        >
                                            {channel.name}
                                        </div>
                                    )
                                })}
                            </div>
                            ) : (<div className="no-channels-available">No channels available</div>)   
                        }
                    </div>
                )}
            </div>
        </div>
    )

    return (
        <>{channelSection}</>
    )
}

export default UploadChannelSection