import "./EditPlaylist.css";
import React, {useEffect, useState} from "react";
import Page, {ErrorPage, LoadingPage} from "../../../components/Page";
import {useBackend} from "../../../utility/Backend";
import {useSWRConfig} from "swr";
import PlaylistMetadataSection from "./PlaylistMetadataSection";
import {Helmet} from "react-helmet-async";
import {useLoginSession} from "../../../stores/loginSession";

function EditPlaylist ({event, playlist, onMutate, refreshPlaylist}) {

    return (
        <Page title={playlist.description}>
            <Helmet>
                <title>{playlist.description}</title>
            </Helmet>
            <div className="edit-playlist-container">
                <PlaylistMetadataSection key={playlist.id}
                                             onMutate={onMutate}
                                             event={event}
                                             playlist={playlist} />
            </div>
        </Page>
    );
}

export default function EditPlaylistWrapper ({id}) {
    // We use the cache purely to get instant rendering when clicking on a search entry
    const {cache} = useSWRConfig();
    const {token} = useLoginSession();
    // We need to live poll the playlist for livestreams in order to get the duration correctly
    // FIXME: Lets see if we can get rid of this if possible - problem is videojs returns inifinity duration.
    // NOTE: Doesn't seem to work if you set refreshInterval to 0, then later add it.
    // Better to set an interval immediately, then remove it when we see its not live. Thats why we initialize as true
    const [enableLivePolling, setEnableLivePolling] = useState(true)

    let fallback = cache.get("editing_playlist");
    if (fallback && fallback.id !== id) fallback = undefined;

    const swrOptions = {
        fallbackData: fallback,
        dedupingInterval: 4 * 1000 // Should be less than refreshInterval if live polling
    }
    if (enableLivePolling) {
        swrOptions.refreshInterval = 5 * 1000
    }
    const {data, error, mutate} = useBackend(
        "/playlist/" + id,
        {include_all_usergroups: true, include_featured_flag: true, include_channels: true, access_token: token},
        swrOptions);

    useEffect(() => {
        if (!data) return setEnableLivePolling(true)
        setEnableLivePolling(data.is_live)
    }, [data])

    if (data) return <EditPlaylist playlist={data} onMutate={(d) => mutate(d)} />

    if (error) return (
        <ErrorPage title="Editing" >
            {(error.status === 404) && (
                <span>Video {id} could not be found</span>
            )}
            {(error.status !== 404) && (
                <span>Unknown error occurred trying to fetch data from server</span>
            )}
        </ErrorPage>
    );

    return <LoadingPage title="Editing" />;
}
