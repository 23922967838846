import {createContext, useContext, useState} from "react";

const ExportsModalContext = createContext(null)

export function ExportsModalProvider ({children}) {
    // TODO get something like Jotai in here, like in sef-web, this sucks to use context for...

    const [isOpen, setIsOpen] = useState(false)
    const context = {
        isOpen,
        setIsOpen,
    }
    return (
        <ExportsModalContext.Provider value={context}>
            {children}
        </ExportsModalContext.Provider>
    )
}

export function useExportsModal () {
    const context = useContext(ExportsModalContext)
    if (context === undefined) throw new Error("useExportsModal used outside of its provider")
    return context
}
