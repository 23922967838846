import {createContext, useContext, useEffect, useRef, useState} from "react";
import {useLocalStorage} from "../../utility/Utilities";

const VideoPlayerContext = createContext(null);

const splitRegex = /^(.+\/playlist\.m3u8\/)([\d:_;]+)(\/.+\.m3u8)/

function getPrerollString (playlist) {
    if (playlist.prerolls?.length > 0) {
        const {asset_id, duration_ms} = playlist.prerolls[0]
        return `${asset_id}:0:${duration_ms}_`
    }
    return ""
}

function getPostrollString (playlist) {
    if (playlist.postrolls?.length > 0) {
        const {asset_id, duration_ms} = playlist.postrolls[0]
        return `_${asset_id}:0:${duration_ms}`
    }
    return ""
}

function computePlaybackUrl (allowPrePostroll, playlist, playbackUrl) {
    if (!allowPrePostroll || !playlist) return playbackUrl

    const regexMatch = playbackUrl.match(splitRegex)
    // Verify that the string actually contains the split-point, not true f ex with live clips
    if (!regexMatch) return playbackUrl
    const [, prefix, video, suffix] = regexMatch

    return (
        prefix +
        getPrerollString(playlist) +
        video +
        getPostrollString(playlist) +
        suffix
    )
}

export function VideoPlayerProvider ({
    children,
    url: originalPlaybackUrl,
    duration: fallbackDuration=0,
    autoPlay=false,
    playlist=null,
    withPrePostroll=false,
}) {

    if (fallbackDuration === 0 && playlist !== null) fallbackDuration = playlist.duration_ms / 1000;

    // TODO This can majorly break things when it comes to editing.
    //      Added quickly, so a very very probably regression for playlist editing...
    originalPlaybackUrl = computePlaybackUrl(withPrePostroll, playlist, originalPlaybackUrl)

    const playerRef = useRef(null);
    const [volume, setVolume] = useLocalStorage("volume", .5);
    const [muted, setMuted] = useLocalStorage("muted", false);
    const [duration, setDuration] = useState(0);
    const [playbackUrl, setPlaybackUrl] = useState(originalPlaybackUrl);
    const [playing, setPlaying] = useState(autoPlay);
    const [isLoading, setIsLoading] = useState(true);
    const [videoProgress, setVideoProgress] = useState(0)
    
    useEffect(() => {
        if (originalPlaybackUrl !== playbackUrl) switchPlayback(originalPlaybackUrl)
    }, [originalPlaybackUrl]);

    function switchPlayback (url, duration=0) {
        if (url === playbackUrl) return;
        setIsLoading(true);
        setDuration(duration);
        setPlaybackUrl(url);
        setPlaying(true);
    }

    function handleVolumeChange (v) {
        setVolume(v);
        setMuted(false);
    }

    function resetPlaybackUrl () {
        switchPlayback(originalPlaybackUrl, fallbackDuration);
    }

    function getCurrentTime () {
        return playerRef.current ? playerRef.current.currentTime() : 0;
    }

    function seekTo (pos, fraction=false) {
        if (!playerRef.current) return

        let position = pos

        if (fraction) {
            const fallbackDuration = (playlist?.duration_ms || 0) / 1000
            const endTimeInSec = duration || fallbackDuration
            const positionInSec = pos * endTimeInSec
            position = Math.max(0, positionInSec)
        }
        // NOTE: ReactPlayer does not seem to handle fractions correctly when the player is in LightMode
        //  i.e. when no preloaded data, before user hits play. Therefore we always convert seconds, just in case.
        playerRef.current.currentTime(position)
    }

    function skip (offset) {
        if (duration === 0) return;
        seekTo(Math.max(0, Math.min(getCurrentTime() + offset, duration)));
    }

    const contextValues = {
        playerRef,
        playlist,
        playbackUrl,
        switchPlayback,
        resetPlaybackUrl,
        volume, setVolume: handleVolumeChange,
        muted, setMuted,
        playing, setPlaying,
        isLoading, setIsLoading,
        duration: duration === 0 ? fallbackDuration : duration,
        setDuration,
        getCurrentTime,
        seekTo,
        skip,
        videoProgress, setVideoProgress,
        autoPlay,
    }

    return (
        <VideoPlayerContext.Provider value={contextValues}>
            {children}
        </VideoPlayerContext.Provider>
    );
}

export function useVideoPlayer () {
    const context = useContext(VideoPlayerContext);
    if (context === undefined) {
        throw new Error("useVideoPlayer must only be used inside of VideoPlayerProvider");
    }
    return context;
}