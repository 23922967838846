import React from "react"
import classNames from "classnames"
import {FiCheckSquare, FiSquare} from "react-icons/fi";
import {BsInfoCircle} from "react-icons/bs";

export const [
    NOT_PUBLIC,
    NOW,
    LATER,
] = [0,1,2]

function UploadPublishPeriod ({publishPeriod, setPublishPeriod}) {

    const publishPeriodOptions = (
        <div className="publish-period-options">
            <div
                onClick={() => setPublishPeriod(NOT_PUBLIC)}
                className={classNames("option-checkbox", {"active": publishPeriod === NOT_PUBLIC})}
            >
                {publishPeriod === NOT_PUBLIC?
                    <FiCheckSquare className="check-box-icon"/> :
                    <FiSquare className="check-box-icon"/>}
                <div className="option-box-title">
                    Not published
                    <div className="option-extra-message"><BsInfoCircle className="info-icon"/> video will be unlisted (can be changed later)</div>
                </div>
            </div>
            <div
                onClick={() => setPublishPeriod(NOW)}
                className={classNames("option-checkbox", {"active": publishPeriod === NOW})}
                >
                {publishPeriod === NOW?
                    <FiCheckSquare className="check-box-icon"/> :
                    <FiSquare className="check-box-icon"/>}
                <div className="option-box-title">
                    Publish immediately
                </div>
            </div>
            {/* <div
                onClick={() => setPublishPeriod(LATER)}
                className={classNames("option-checkbox", {"active": publishPeriod === LATER})}>
                {publishPeriod === LATER?
                    <FiCheckSquare className="publish-check-box"/> :
                    <FiSquare className="publish-check-box"/>}
                <div className="publish-later">Publish later</div>
                {publishPeriod === "later" && <DateAndTimePicker toTimeInput={updateTime} nowBtn/>}
            </div> */}
        </div>
    )

    return (
        <>{publishPeriodOptions}</>
    )
}

export default UploadPublishPeriod