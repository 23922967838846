import { useBackend } from "./Backend"
import { useLoginSession } from "../stores/loginSession"
import { useGetUsersGroups, useCheckIsAdmin } from "./UserGroups"
import {useMemo} from "react";

export function useGetChannels () {
    const {token} = useLoginSession()
    const query = {access_token: token}
    const usersUserGroups = useGetUsersGroups()
    const [,isAdmin] = useCheckIsAdmin();

    const {data: channelsData} = useBackend("/channel", query)

    return useMemo(() => {
        const channels = channelsData?.channels || []

        const sortingFunction = (a, b) => {
            const groupA = a.name.toLowerCase()
            const groupB = b.name.toLowerCase()
            return (groupA < groupB)? -1 : (groupA > groupB)? 1:0
        }

        if (isAdmin) return channels.sort(sortingFunction)

        const usersUserGroupsId = usersUserGroups.map(group => group.id)

        const channelMap = channels.reduce((map, channel) => {
            for (const group of channel.usergroups) {
                if (usersUserGroupsId.includes(group.id)) map.set(channel.id, channel)
            }
            return map
        }, new Map())

        const nonAdminChannels = Array.from(channelMap.values())

        return nonAdminChannels.sort(sortingFunction)
    }, [channelsData, isAdmin, usersUserGroups,])
}