import React from "react";
import { Link } from "react-router-dom";

function StreamGameDetails ({ streamPlaylist }) {

    const game = streamPlaylist.game;
    if (!game) return null

    const toMatch = `/library/match/${game.id}`;
    return (
        <div className="data-info-cont">
            <div className="data-info-head-title">Game details</div>
            <div className="data-info">
                <div className="data-info-title">Date</div>
                <div className="data-info-value">{game.date}</div>
            </div>
            <div className="data-info">
                <div className="data-info-title">Match</div>
                <div className="data-info-value sd-game-details">
                    <div>{game.home_team.name}</div>
                    <img src={game.home_team.logo_url} alt="logo" className="sgd-logo"/>
                    <div>{game.home_team_goals}</div>
                    <div>-</div>
                    <div>{game.visiting_team_goals}</div>
                    <img src={game.visiting_team.logo_url} alt="logo" className="sgd-logo"/>
                    <div>{game.visiting_team.name}</div>
                </div>
            </div>
            <div className="confirm-cancel-btn-cont margin-top">
                <button type="button">
                    <Link to={toMatch} className="stream-to-match">Events of this game</Link>
                </button>
            </div>
        </div>
    )
}

export default StreamGameDetails