import React, {useState, useMemo, useRef, useEffect} from "react"
import Config from "../../utility/Config";
import SelectedCategory from "./SelectedCategory";
import CustomModal from "../../components/CustomModal";
import { ClickOutside } from "../../utility/Utilities";
import { useGetCategories, useGetOfficialTags } from "../../utility/UseGetCategories";
import "./UploadCategorySection.css"
import "./CategoryParams.css"
import {TiArrowSortedDown} from "react-icons/ti";
import {FiEdit3} from "react-icons/fi";
import {FaTrashAlt} from "react-icons/fa";

function CategoryDropdown ({availableCategories, onClick}) {

    return (
        <div className="upload-categories-list-cont">
            <ul>
                {availableCategories.map((category) => {
                    let categoryName = Config.categories[category] || Config.rollCategories[category] || category
                    // TODO specific SHL shoot out penalty shot
                    if (categoryName === "shootoutpenaltyshot") categoryName = "shoot out penalty shot"
                    return (
                        <li key={category} className="category-single" onClick={() => onClick(category)}>
                            {categoryName}
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

function SelectedCategoryNoParam ({category, onDiscard}) {

    const [isEditCategory, setIsEditCategory] = useState(false)

    const categoryDisplayName = Config.categories[category] || Config.rollCategories[category] || category

    return (
        <div>
            <div onClick={() => setIsEditCategory(true)} className="selected-category-single no-param">
                <div className="selected-category-title">{categoryDisplayName}</div>
                <FiEdit3 className="add-category-icon"/>
            </div>
            {isEditCategory && (
                <CustomModal isOpen onRequestClose={() => setIsEditCategory(false)} className="mini">
                    <div className="category-params-modal-cont">
                        <div className="no-category-param">
                            <i>Category has no optional information</i>
                        </div>
                        <div className="confirm-cancel-btn-cont full">
                            <button type="button" onClick={() => setIsEditCategory(false)}>Close</button>
                            <button 
                                type="button" 
                                onClick={() => onDiscard(category)}
                                className="red-btn"
                                >
                                <span><FaTrashAlt className="icon-in-btn"/>Remove category</span>
                            </button>
                        </div>
                    </div>
                </CustomModal>
            )}
        </div>
    )
}

export default function UploadCategorySection ({tags, setTags}) {
    // example tags [
    // {"action": "training"},
    // {"action": "interview", "player": {"type": "player", "id": 123, "value": "John Doe"}},
    // {"action": "interview", "player": {"type": "player", "id": 456, "value": "Will Smith"}},
    // ]

    // FIXME temporary workaround due to some SHL tags being incorrectly double-nested as [[{...}]] instead of [{...}]
    tags = tags?.flat()

    const dropdownListRef = useRef(null) 
    const categoriesList = useGetCategories()
    const officialTags = useGetOfficialTags()

    const [isListOpen, setIsListOpen] = useState(false)
    const [hasSelectedWarning, setHasSelectedWarning] = useState(false)
    
    ClickOutside(dropdownListRef, setIsListOpen)

    const matchTags = Object.keys(officialTags).map(tag => tag)
        .filter(tag => tag !== "highlights")

    // Group the tags by category
    const categories = useMemo(() => (
        tags.reduce((map, tag) => {
            const list = map.get(tag.action) || []
            list.push(tag)
            map.set(tag.action, list)
            return map
        }, new Map())
    ), [tags])

    useEffect(() => {
        if (Array.from(categories).length === 0) setHasSelectedWarning(false)
    }, [categories])

    useEffect(() => {
        const closeWarning = setInterval(()=> {
            if (hasSelectedWarning) setHasSelectedWarning(false)
        }, 5000)
        return () => clearInterval(closeWarning);
    }, [hasSelectedWarning])

    const isShl = Config.association === "SHL"
    const remainingCategories = !isShl && Object.keys(categoriesList).filter(c => !categories.has(c))
    // TODO in case might need to see this again
    // const availableCategories = isShl? matchTags : matchTags.concat(remainingCategories)
    //     .sort((a,b) => a.localeCompare(b))

    // shl categories is official match tags
    const availableCategories = isShl? matchTags : remainingCategories

    const addCategory = (category) => {
        setIsListOpen(false)
        setTags([...tags, {"action": category}])
    }

    const removeCategory = (category) => {
        setTags(tags.filter(({action}) => action !== category))
    }

    const changeCategory = (category, updatedTags) => {
        const otherTags = tags.filter(({action}) => action !== category)
        setTags([...otherTags, ...updatedTags])
    }

    const renderSelectedCategory = (category, categoryTags) => {

        const tagWithParams = matchTags.includes(category) || category === "interview"
        const paramsFromTag = tagWithParams && officialTags[category]

        if (!tagWithParams) return (
            <SelectedCategoryNoParam category={category} onDiscard={removeCategory}/>
        ) 

        return (
            <SelectedCategory
                category={category}
                tags={categoryTags} 
                tagWithParams={tagWithParams}
                paramsFromTag={paramsFromTag} 
                changeCategory={changeCategory} 
                removeCategory={removeCategory}/>
        )
    }

    const hasSelectedCategory = Array.from(categories).length !== 0

    const categoriesSection = (
        <>  
            <div className="selected-category-list">
                {tags.length !== 0 && 
                    Array.from(categories).map(([category, categoryTags]) => {
                        return (
                            <div key={category}>
                                {renderSelectedCategory(category, categoryTags)}
                            </div>
                        )
                    })}
            </div>
            {!hasSelectedCategory && (
                <div ref={dropdownListRef} className="upload-dropdown-cont">
                    <div onClick={() => setIsListOpen(!isListOpen)} className="upload-dropdown-title">
                        Add category
                        <TiArrowSortedDown/>
                    </div>
                    {isListOpen && (
                        <CategoryDropdown availableCategories={availableCategories} onClick={addCategory} />
                    )}
                </div>
            )}
        </>
    )

    return (<>{categoriesSection}</>)
}