import {copyToClipboard} from "../../../utility/Utilities";
import Config from "../../../utility/Config";
import {useEffect, useState} from "react";

export default function EmbedCodeButton ({playlistId}) {

    const [hasCopied, setHasCopied] = useState(false)

    useEffect(() => {
        if (!hasCopied) return
        const timeout = setTimeout(() => setHasCopied(false), 750)
        return () => clearTimeout(timeout)
    })

    function copyEmbedCode () {
        const embedUrl = `${Config.webHref}/embed/playlist/${playlistId}`;
        const embedCode = `<div style="width:100%;position:relative;padding-bottom:56.25%;"><iframe src="${embedUrl}" allow="fullscreen" allowfullscreen="true" frameborder="0" border="0" style="width:100%;height:100%;border:none;position:absolute;top:0;bottom:0;left:0;right:0;"></iframe></div>`;

        copyToClipboard(embedCode);
        setHasCopied(true)
    }

    return (
        <button className="small-btn" onClick={copyEmbedCode} style={{minWidth: "190px"}}>
            {hasCopied ? "Copied to clipboard" : "Copy code for embedding"}
        </button>
    )
}