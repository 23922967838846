import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {getDisplayDuration, tagToIcon} from "../../../../utility/Utilities";
import "./CompilationEditingSection.css";
import {FaTimes} from "react-icons/fa";
import {useCompilation} from "../../../../stores/compilation";

function CompilationDragAndDrop () {

    const {
        clips,
        editIndex,
        isPreviewMode,
        setIsPreviewMode,
        setEditIndex,
        removeFromCompilation,
        reorderClips,
    } = useCompilation();

    const handleRemoveClip = (e, idx) => {
        e.stopPropagation();
        removeFromCompilation(idx);
    }

    const handleClick = (idx) => {
        if (isPreviewMode) setIsPreviewMode(false);
        setEditIndex(idx);
    }

    const totalDuration = clips.reduce((sum, c) => sum + (c.to_timestamp - c.from_timestamp), 0);
    const displayTotalDuration = getDisplayDuration(totalDuration);

    const onDragEnd = (e) => {
        const {source, destination} = e;
        if (!e.destination) return;
        reorderClips(source.index, destination.index);
    }

    const singleClip = (
        clips.map((c, idx) => {
            const displayDuration = getDisplayDuration(c.to_timestamp - c.from_timestamp);
            const hasTags = c.tags.length !== 0;
            return (
                <Draggable key={c.uniqueId} draggableId={c.uniqueId} index={idx}>
                    {(provided) => {
                        return (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                key={c.uniqueId}
                                className={`compilation-single-clip ${(editIndex === idx && !isPreviewMode) ? "active" : ""}`}
                                onClick={() => handleClick(idx)}
                            >
                                <div className="list-duration-description" title={c.description}>
                                    <div className="list-dur">{displayDuration} </div>
                                    {hasTags && (<div className="list-tag">{tagToIcon(c.tags[0].action)}</div>)}
                                    <div className="list-desc">{c.description}</div> 
                                </div>
                                {clips.length > 1 && (
                                    <FaTimes onClick={(e) => handleRemoveClip(e, idx)} className="remove-clip-icon"/>
                                )}
                            </div>
                        )
                    }}
                </Draggable>
            )
        })
    );

    const DragAndDropField = (
        <div className="draggable-cont">
            <div className="drag-to-reorder">Drag to reorder</div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable-id" key={"droppable-key"}>
                    {(provided) => {
                        return (
                            <div {...provided.droppableProps} ref={provided.innerRef} className="droppable-cont">
                                {singleClip}
                                {provided.placeholder}
                            </div> 
                        )
                    }}
                </Droppable>
            </DragDropContext>
        </div>
    );

    return (
        <div className="compilation-list-cont">
            {DragAndDropField}
            <div className="compilation-list-btn-cont">
                <div className={`${editIndex === null? "length-duration-playing" : "length-duration"}`}>
                    {editIndex === null? "Playing " : ""}
                    {clips.length} videos - {displayTotalDuration}
                </div>
                <button 
                    type="button" 
                    className="compilation-preview" 
                    onClick={() => setIsPreviewMode(!isPreviewMode)}
                    >
                    {isPreviewMode ? "Exit preview" : "Preview compilation"}
                </button>
            </div>
        </div>
    );
}

export default CompilationDragAndDrop;
