import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {useLoginSession} from "../../stores/loginSession";
import Layout from "./Layout";
import Config from "../../utility/Config";

export default function SsoLoginCallbackPage () {
    const navigate = useNavigate();
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const {ssoLogin} = useLoginSession();

    const code = query.get("code");
    const refreshToken = query.get("RefreshToken");
    const redirectPath = query.get("state") || Config.defaultPath

    useEffect(() => {
        ssoLogin(code, refreshToken);
        navigate(redirectPath, {replace: true});
    }, [query])

    return <Layout />;
}