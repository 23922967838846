import React, {createContext, useContext, useEffect, useState}  from "react";
import { FaRegCheckCircle, FaTimes } from "react-icons/fa";
import { BsExclamationCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import "../pages/Layout/FeedbackMessage.css";

const FeedbackMessageContext = createContext(null);

function FeedbackMessageProvider ({children}) {

    const [feedbackMsg, setFeedbackMsg] = useState("");
    const [type, setType] = useState(null);
    const [link, setLink] = useState(null)

    // TODO for export
    const bannerDuration = type === "exportVideo"? 500000 : 5000

    useEffect(() => {
        const closeFeedback = setInterval(()=> {
            if (feedbackMsg) showFeedback();
        }, bannerDuration);
        return () => clearInterval(closeFeedback);
    }, [feedbackMsg])

    const showFeedback = (type=null, message="", link=null) => {
        if (type === "success") setFeedbackMsg(message);
        // TODO for export
        if (type === "exportVideo") {
            setFeedbackMsg(message)
            setType("exportVideo");
        }
        if (type === "warning") {
            setFeedbackMsg(message);
            setType("warning");
        }
        if (link) {
            setLink(link)
        }
        if (type === null){
            setFeedbackMsg(message);
            setType(null);
            setLink(null)
        }
    }

    // TODO for export
    const exportBanner = (
        <div className="export-banner-cont">
            <div onClick={() => showFeedback()} className="export-banner-close">
                Close
                <FaTimes/>
            </div>
            {feedbackMsg}
        </div>
    );

    const feedbackBanner = (
        <div className={`feedback-cont ${type === "warning"? "feedback-warning" : ""}`}>
            <div className="feedback-icon-msg">
                {type === "warning"? (<BsExclamationCircle className="feedback-icon"/>
                ) : (
                    <FaRegCheckCircle className="feedback-icon"/>)}
                {feedbackMsg && feedbackMsg.toString()}
            </div>
            {link && <Link to={link} className="feedback-msg-link">Go to video</Link>}
            <FaTimes className="feedback-remove" onClick={() => showFeedback()}/>
        </div>
    );

    const feedbackMessage = type === "exportVideo"? exportBanner : feedbackBanner

    const context = {
        showFeedback,
        feedbackMsg,
        feedbackMessage,
    }

    return (
        <FeedbackMessageContext.Provider value={context}>
            {children}
        </FeedbackMessageContext.Provider>
    )
}

function useFeedbackMessage () {
    const context = useContext(FeedbackMessageContext);
    if (context === undefined) {
        throw new Error("useFeedbackMessage used outside its provider");
    }
    return context;
}

export {FeedbackMessageProvider, useFeedbackMessage}