import React from "react";
import "./css/NavLink.css";
import {Link, useLocation, useSearchParams} from "react-router-dom";
import {objToQuerystring} from "../utility/Utilities";

export default function NavLink ({
    onClick,
    children,
    to,
    base=null,
    forceActive=false,
    withQuery=false,
    small=false,
}) {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    
    let path = to;
    if (withQuery) {
        if (withQuery instanceof Array) {
            const paramsToInclude = withQuery.reduce((agg, key) => {
                const value = searchParams.get(key);
                if (value) agg[key] = value;
                return agg;
            }, {});
            path += objToQuerystring(paramsToInclude);
        } else {
            path += location.search;
        }
    }

    let cls = "";
    if (forceActive || location.pathname.startsWith(base ? base : to)) cls += " active";
    if (small) cls += " small";
    return (
        <li className="nav-link" onClick={onClick}>
            <Link to={path} className={cls}>
                {children}
            </Link>
        </li>
    );
}
