import React from "react";
import {useSWRConfig} from "swr";
import {useNavigate} from "react-router-dom";
import VideoSearchEntry from "./VideoSearchEntry";
import {useBackend} from "../utility/Backend";

export default function EventEntry ({
    event,
    tabIndex,
    active,
    selectedVideos,
    selectAll,
    addVideoToList,
    removeVideoFromList,
}) {
    const {
        tag,
        game_id,
        playlist,
        wall_clock_time: wct,
    } = event;

    const {cache} = useSWRConfig();
    const navigate = useNavigate();

    // Only fetch game object if we cannot get it from the playlist object
    const gamepath = (playlist?.game || !game_id) ? null : `/game/${game_id}`
    const {data: gameData} = useBackend(gamepath)
    const game = gameData || playlist?.game || null

    const gameTime = Math.ceil(event.game_time / 60);

    function getTeamLogo () {
        if (!game || !tag || !tag.team) return null;
        const teamId = tag.team.id;
        let team;
        if (teamId === game.home_team.id) team = game.home_team;
        if (teamId === game.visiting_team.id) team = game.visiting_team;
        if (!team) return null;
        return <img src={team.logo_url} alt={team.name} />
    }

    const urlPathname = window.location.pathname
    const urlAfterLibrary = urlPathname.substring(urlPathname.indexOf("y") + 1);

    function onClick () {
        cache.set("editing_event", event);
        // Doing this a little more manually to avoid using a hook in dozens/hundreds of small components
        const q = new URLSearchParams(window.location.search);
        q.set("editing", "event_" + event.id);
        navigate("/library" + urlAfterLibrary + "?" + q.toString());
        return false;
    }

    return (
        <VideoSearchEntry
            tabIndex={tabIndex}
            active={active}
            onClick={onClick}
            playlist={playlist}
            game={game}
            timestamp={wct}
            isEvent
            tag={event.tag}
            gameTime={gameTime}
            teamLogo={getTeamLogo()}
            selectedVideos={selectedVideos}
            selectAll={selectAll}
            addVideoToList={addVideoToList}
            removeVideoFromList={removeVideoFromList}
        />
    );
}