import { createBrowserHistory } from "history";
import {useEffect, useLayoutEffect, useState} from "react";
import {Router} from "react-router-dom";

export const history = createBrowserHistory();

export function useNavigationBlock (warningMessage, isActive) {
    useEffect(() => {
        if (!isActive) return
        const unblock = history.block(tx => {
            if (window.confirm(warningMessage)) {
                unblock()
                tx.retry()
            }
        })
        return () => unblock()
    }, [isActive, warningMessage])
}

// Mostly a workaround for exposing the history object to stop navigation
// See https://stackoverflow.com/a/70095819
export default function CustomRouter ({
    basename,
    children,
}) {

    const [state, setState] = useState({
        action: history.action,
        location: history.location,
    });

    useLayoutEffect(() => history.listen(setState), [history]);

    return (
        <Router
            basename={basename}
            children={children}
            location={state.location}
            navigationType={state.action}
            navigator={history}
        />
    );
}