import React, {useState, useEffect} from "react";
import { useUpdateSearchParams } from "../utility/Utilities";
import { useBackend } from "../utility/Backend";
import { useInView } from "react-intersection-observer";
import VideoAction from "./VideoAction";
import { Paging } from "../utility/Paging";
import "./css/SearchResults.css";
import "./css/VideoAction.css";
import {MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox} from "react-icons/md";
import classNames from "classnames";
import { LoadingPage } from "./Page";

function getArrayFromUnknownApiResponse (data) {
    for (const key of Object.keys(data))
        if (data[key] instanceof Array) return data[key];
    return null;
}

export default function SearchResults ({
    path,
    render,
    selectedVideos, 
    clearSelectedList, 
    selectAll, 
    handleSelectAll,
    renderMatch=false,
    refreshInterval=null,
    disableFooter = false,
    disableAction = false,
    enableAutoScroll=false,
    showAll=false,
    matchEvents=false,
}) {

    const [searchParams, updateSearchParams, ] = useUpdateSearchParams();
    const [showSelectAll, setShowSelectAll] = useState(false)

    const clipEntryPageParam = searchParams.get("page")

    let clipsPerPage = 10
    const page = clipEntryPageParam ? parseInt(clipEntryPageParam) : 1
    let from = clipEntryPageParam ? (page-1) * clipsPerPage : 0

    let query = {count: 300}

    if (!showAll) {
        query = {
            count: clipsPerPage,
            from: from
        }
    }

    const {data} = useBackend(path, query, {
        dedupingInterval: Math.min(120 * 1000, refreshInterval || Infinity),
        focusThrottleInterval: 120 * 1000,
        refreshInterval: refreshInterval,
    })
    
    const { ref: bottomListRef, inView: bottomListInView } = useInView({initialInView: false})

    useEffect(() => {
        if (bottomListInView) setShowSelectAll(true)
    }, [bottomListInView])

    useEffect(() => {
        const bottomListId = document.getElementById("bottom-list")
        if (enableAutoScroll && bottomListInView) bottomListId.scrollIntoView();
    }, [data, enableAutoScroll, bottomListInView])

    if (!data) return <LoadingPage/>

    const onChangePage = (page) => {
        if (page > 1) updateSearchParams({"page": page})
        else updateSearchParams("page", null)
    }

    let entries = getArrayFromUnknownApiResponse(data);

    const noData = data.total === 0
    const totalPage = Math.ceil(data.total / clipsPerPage)

    const selectAllButton = (!disableAction && showAll && showSelectAll && !noData) && (
        <div className="action-select-all" onClick={handleSelectAll}>
            {selectAll? <MdOutlineCheckBox className="unselect-all"/> : <MdOutlineCheckBoxOutlineBlank className="select-all"/>}
            {selectAll? "Clear all" : "Select all"}
        </div>
    )
     
    const autoRefreshLabel = (enableAutoScroll && showAll && !noData) && (
        <div className="auto-refresh-label">
            Automatically refreshing
            <span className="loading-dot">.</span>
            <span className="loading-dot">.</span>
            <span className="loading-dot">.</span>
        </div>
    )

    if (noData) return <div className="no-results">No results</div>

    const renderList = renderMatch ? (
        <ul className="clip-entry-list with-paging scrollable">
            {render(entries)}
        </ul>
    ) : (
        <ul className={classNames("clip-entry-list scrollable", {"with-paging": !showAll && !disableFooter})}>
            {entries.map((e, idx) => render(e, idx))}
            {showAll && <div id="bottom-list" ref={bottomListRef} className="bottom-list"></div>}
        </ul>
    )

    return (
        <div className={classNames("search-results", {"match-events": matchEvents})}>
            {(!disableAction && !noData) && (
                <VideoAction 
                    selectedVideos={selectedVideos}
                    clearSelectedList={clearSelectedList}/>
            )}
            {renderList}
            {selectAllButton}
            {autoRefreshLabel}
            {showAll ? (
                <div className={classNames("show-total-results", {"padding-top": !showSelectAll})}>Showing {data.total} results</div>
                ) : 
                !disableFooter && (
                    <Paging page={page} pageCount={totalPage} onChange={onChangePage}/>
                )}
        </div>
    );
}