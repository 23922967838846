import React from "react"
import SmallDropdown from "../../../components/SmallDropdown"
import { capitalizeFirstLetter, showErrorMessage } from "../../../utility/Utilities"
import classNames from "classnames"
import { RiArrowGoBackFill } from "react-icons/ri";
import {FiSquare, FiCheckSquare} from "react-icons/fi";

export function TextField ({schema, value, onChange, hasInputError}) {
    if (!schema) return null
    return (
        <div className="input-container">
            <label className="input-title">{capitalizeFirstLetter(schema.title)}</label>
            <input
                type="text"
                onChange={(e) => onChange(e.target.value)}
                value={value}/>
            {hasInputError && showErrorMessage("Package name min 5 characters", value.length < 5)}
        </div>
    )
}

export function BooleanField ({schema, value, onChange}) {
    if (!schema) return null
    return (
        <div className="input-container">
            <label className="input-title">{capitalizeFirstLetter(schema.title)}</label>
            <div className="package-input-description">{schema.description}</div>
            <div onClick={() => onChange(!value)} className={classNames("toggle-switch", {"active": value})}>
                <div className="toggle-button"></div>
            </div>
        </div>
    )
}

export function DropdownListField ({schema, value, onChange}) {
    if (!schema) return null
    return (
        <div className="input-container">
            <label htmlFor="" className="input-title">{capitalizeFirstLetter(schema.title)}</label>
            <div className="package-input-description">{schema.description}</div>
            <SmallDropdown 
                value={value}
                options={schema.options}
                onChange={(v) => onChange(v)}/>
        </div>
    )
}

export function MultipleSelectField ({schema, value, onChange, hasInputError=false}) {

    if (!schema) return null

    const onChangeSelection = (v) => {
        let updatedList
        if (value.includes(v)) updatedList = value.filter(i => i !== v)
        else updatedList = [...value, v]
        onChange(updatedList)
    }

    return (
        <div className="input-container">
            <label className="input-title">{capitalizeFirstLetter(schema.title)}</label>
            <div className="package-input-description">{schema.description}</div>
            <div className="multiple-options-cont">
                {schema.options.map((v) => {
                    const isSelected = value.includes(v)
                    return (
                        <div key={v} className="single-multiple-option-cont">
                            <div className="single-multiple-option-name">{capitalizeFirstLetter(v)}</div>
                            <div onClick={() => onChangeSelection(v)} className={classNames("select-check-icon", {"active": isSelected})}>
                                {isSelected ? <FiCheckSquare/> : <FiSquare/> }
                            </div>
                        </div>
                    )
                })}
            </div>
            {hasInputError && showErrorMessage("Must at least one selected", value.length === 0)}
        </div>
    )
}

export function ColorSelectionField ({schema, value, onChange}) {

    if (!schema) return null

    const isDefaultColor = schema.default === value || !value
    
    return (
        <div className="input-container">
            <label className="input-title">{capitalizeFirstLetter(schema.title)}</label>
            <div className="package-input-description">{schema.description}</div>
            <div className="color-input-cont">
                <input 
                    type="color"
                    value={value || "#000000"}
                    onChange={(e) => onChange(e.target.value)}
                    className="color-input"
                    />
                <div className="color-hex-text">{value}</div>
                {!isDefaultColor && (
                    <div onClick={() => onChange(schema.default)} className="restore-default-color">
                        <RiArrowGoBackFill/>
                        Default color
                    </div>
                )}
            </div>
        </div>
    )
}