import React, { useState, useRef } from "react"
import { useBackend } from "../../utility/Backend";
import classNames from "classnames"
import { ClickOutside } from "../../utility/Utilities";
import {BsInfoCircle} from "react-icons/bs";
import "./Payment.css"
import { getDateFormat } from "../../utility/Utilities";
import {TiArrowSortedDown} from "react-icons/ti";
import {FiCheckSquare, FiSquare} from "react-icons/fi";

function PricePlansDropdown ({merchantId, price, setPrice, videoStartsUnderTwoHours=false}) {

    const [showPrices, setShowPrices] = useState(false)

    const dropdownListRef = useRef(null)
    ClickOutside(dropdownListRef, setShowPrices)

    const {data: pricesData} = useBackend("/cee_merchant/" + merchantId + "/price_plan", {});
    
    if (!pricesData) {
        return (
            <div className="select-merchant-for-price">
                Getting prices...
            </div>
        )
    }
    
    const prices = pricesData?.price_plans || []

    const handleShowPrices = () => {
        if (videoStartsUnderTwoHours) return null
        else setShowPrices(!showPrices)
    }

    const handleSelectPrice = (price) => {
        setPrice(price)
        setShowPrices(false)
    }

    if (prices?.length === 0) {
        return (
            <div className="select-merchant-for-price">
                No available price
            </div>
        )
    }

    return (
        <div ref={dropdownListRef} className="upload-dropdown-cont">
            <div onClick={handleShowPrices} className="upload-dropdown-title">
                {price? price.name : "Select price"}
                <TiArrowSortedDown/>
            </div>
            {showPrices && (
                <div className="upload-dropdown-list">
                    <ul className="payment-option-list">
                        {prices.map((p) => {
                            return (
                                <li
                                    key={p.name}
                                    onClick={() => handleSelectPrice(p)}
                                    className={classNames("payment-option-single", {"active": p.price_amount === price?.price_amount})}
                                    >
                                    {p.name}
                                </li>
                            )
                        })}
                    </ul> 
                </div>        
            )}
        </div>
    )
}

export default function PaymentInputField ({
    merchant, 
    price, 
    paymentPeriod,
    paymentActive, 
    setMerchant, 
    setPrice, 
    setPaymentPeriod,
    setPaymentActive, 
    startTime,
    editPayment=false,
}) {

    const [selectedMerchantId, setSelectedMerchantId] = useState(merchant?.id)
    const [showMerchants, setShowMerchants] = useState(false)
    const [showPeriods, setShowPeriods] = useState(false)

    const dropdownListRef = useRef(null)
    const periodListRef = useRef(null)
    ClickOutside(dropdownListRef, setShowMerchants)
    ClickOutside(periodListRef, setShowPeriods)

    const {data: merchantsData} = useBackend("/cee_merchant", {});
    const merchants = merchantsData?.cee_merchants

    const videoStartsUnderTwoHours = editPayment && (new Date(startTime) - new Date()) < 7200000

    const handleSelectMerchant = (merchant) => {
        setMerchant(merchant)
        setSelectedMerchantId(merchant.id)
        setShowMerchants(false)
        setPrice(null)
        setPaymentPeriod(3)
    }

    const handleSelectPeriod = (period) => {
        setPaymentPeriod(period)
        setShowPeriods(false)
    }

    const activatePayment = () => {
        if (videoStartsUnderTwoHours) return null
        else setPaymentActive(true)
    }

    const deactivatePayment = () => {
        if (videoStartsUnderTwoHours) return null
        else setPaymentActive(false)
    }

    const handleShowMerchants = () => {
        if (videoStartsUnderTwoHours) return null
        else setShowMerchants(!showMerchants)
    }

    const handleShowPeriods = () => {
        if (videoStartsUnderTwoHours) return null
        else setShowPeriods(!showPeriods)
    }

    const merchantOptions = (
        <div ref={dropdownListRef} className="input-container payment-dropdown">
            <label className="input-title">Merchant</label>
            <div onClick={handleShowMerchants} className="upload-dropdown-cont">
                <div className="upload-dropdown-title">
                    {merchant? merchant.name : "Select merchant"}
                    <TiArrowSortedDown/>
                </div>
                {showMerchants && (
                    <div className="upload-dropdown-list">
                        <ul className="payment-option-list">
                            {(!merchants || merchants.length === 0)? (
                                <li onClick={() => setShowMerchants(false)} className="payment-option-single no-merchant">
                                    No available merchant
                                </li>
                            ) : (
                                merchants.map((m) => {
                                    return (
                                        <li
                                            key={m.id}
                                            onClick={() => handleSelectMerchant(m)}
                                            className={classNames("payment-option-single", {"active": m.name === merchant?.name})}
                                            >
                                            {m.name}
                                        </li>
                                    )
                                })
                            )}
                            
                        </ul>
                    </div>
                )}
            </div>
        </div>
    )

    const priceOptions = (
        <div className="input-container payment-dropdown">
            <label className="input-title">Price</label>
            {merchant? (
                <PricePlansDropdown 
                    merchantId={selectedMerchantId} 
                    price={price} 
                    setPrice={setPrice}
                    videoStartsUnderTwoHours={videoStartsUnderTwoHours}/>
            ) : (
                <div className="select-merchant-for-price">
                    Select merchant to show prices
                </div>
            )}
        </div>
    )

    let startDate = new Date(startTime)
    startDate.setDate(startDate.getDate() + paymentPeriod)
    
    // TODO temporary
    const periodsList = [3, 7, 30]

    const paymentPeriodOptions = (
        <div ref={periodListRef} className="input-container payment-dropdown">
            <label className="input-title">Payment required for</label>
            <div onClick={handleShowPeriods} className="upload-dropdown-cont">
                <div className="upload-dropdown-title">
                    {paymentPeriod? `${paymentPeriod} days` : "Select period"}
                    <TiArrowSortedDown/>
                </div>
                {paymentPeriod && (
                    <div className="payment-period-until">Until {getDateFormat(startDate, true, true)}</div>
                )}
                {showPeriods && (
                    <div className="upload-dropdown-list">
                        <ul className="payment-option-list">
                            {periodsList.map((p) => {
                                return (
                                    <li
                                        key={p}
                                        // TODO always 3 days for now
                                        onClick={() => handleSelectPeriod(p)}
                                        className={classNames("payment-option-single", {"active": p === paymentPeriod})}
                                        >
                                        {p} days
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    )

    return (
        <div className={classNames("payment-cont", {"disabled": videoStartsUnderTwoHours})}>
            {videoStartsUnderTwoHours && (
                <div className="edit-payment-info">
                    <BsInfoCircle className="info-icon"/>
                    Payment is not editable from 2 hours before video starts
                </div>
            )}
            <div
                onClick={deactivatePayment}
                className={classNames("option-checkbox", {"active": !paymentActive})}
                >
                {!paymentActive?
                    <FiCheckSquare className="check-box-icon"/> :
                    <FiSquare className="check-box-icon"/>}
                <div className="option-box-title">
                    Disabled
                </div>
            </div>
            <div
                onClick={activatePayment}
                className={classNames("option-checkbox", {"active": paymentActive})}
                >
                <div>
                    <div className="enabled-payment-title">
                        {paymentActive?
                            <FiCheckSquare className="check-box-icon"/> :
                            <FiSquare className="check-box-icon"/>}
                        <div className="">
                            Enabled
                        </div>
                    </div>
                    {paymentActive && (
                        <div className="payment-options-cont">
                            <div className="merchant-price">
                                {merchantOptions}
                                {priceOptions}
                            </div>
                            {paymentPeriodOptions}
                        </div>
                    )}
                </div>
            </div>
            
        </div>
    )
}