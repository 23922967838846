import React, { useState } from "react";
import Backend from "../../../utility/Backend";
import CustomModal from "../../../components/CustomModal";
import {useLoginSession} from "../../../stores/loginSession";
import { useFeedbackMessage } from "../../../stores/FeedbackMessage";
import UploadImage from "../../../components/UploadImage";
import { useMutateByRegex } from "../../../utility/Utilities";
import "./TeamDetails.css";

function EditTeam ({ refresh, team, closeEditTeam}) {

    const {token} = useLoginSession();
    const {showFeedback} = useFeedbackMessage();
    const mutateByRegex = useMutateByRegex()

    const teamCoach = team.coach? team.coach : "";
    const teamShortName = team.short_name? team.short_name : "";

    const [coach, setCoach] = useState(teamCoach);
    const [shortName, setShortName] = useState(teamShortName);
    const [logoFile, setLogoFile] = useState(null);
    const [logoUrl, setLogoUrl] = useState(team.logo_url);
    const [uploadLogo, setUploadLogo] = useState(false);

    const changeImage = (imgAndUrl) => {
        setLogoFile(imgAndUrl.imageFile);
        setLogoUrl(imgAndUrl.imageUrl);
    }

    const openUploadLogo = () => {
        setUploadLogo(true);
    }

    const closeUploadLogo = () => {
        setUploadLogo(false);
    }

    const resetEdit = () => {
        setCoach(team.coach);
        setShortName(team.short_name);
        setLogoFile(null);
        setLogoUrl(team.logo_url);
        closeEditTeam();
        showFeedback();
        if (logoUrl?.startsWith("blob:")) URL.revokeObjectURL(logoUrl)
    }

    const submitTeamEdit = () => {

        const query = {access_token: token};

        const newTeamDetails = {}
        if (teamCoach !== coach) newTeamDetails.coach = coach || null
        if (teamShortName !== shortName) newTeamDetails.short_name = shortName || null
        
        const putTeamDataAsString = JSON.stringify(newTeamDetails)

        if (putTeamDataAsString !== "{}") {
            Backend.put("/team/" + team.id, putTeamDataAsString, query)
                .then(({error}) => {
                    if (error) {
                        console.error("Failed to PUT", error);
                        resetEdit();
                        showFeedback("warning", "Failed to edit team, " + error);
                    } else {
                        console.log("Team details edited");
                        if (!logoFile) {
                            resetEdit();
                            mutateByRegex(/^\/team\//)
                            refresh();
                            showFeedback("success", "Team edited successfully!");
                        };
                    }
                });
        }

        if (logoFile){
            let body = new FormData();
            body.append("logo", logoFile);
            const logo = body;
            console.log(body);
            Backend.post("/team/" + team.id + "/logo", logo, query, {json:false})
                .then(({error}) => {
                    if (error) {
                        console.error("Failed to POST", error);
                        resetEdit();
                        showFeedback("warning", "Failed to edit team, " + error);
                    } else {
                        console.log("Team logo edited");
                        mutateByRegex(/^\/team\//)
                        refresh();
                        resetEdit();
                        showFeedback("success", "Team edited successfully!");
                    }
                });
            return false;
        }
        return false;
    }

    const editTeam = (
        <div className="edit-team-cont">
            <div className="edit-team">Edit team</div>
            <form className="edit-team-form">
                <div className="input-container">
                    <label className="input-title">Team</label>
                    <div className="et-value">{team.name}</div>
                </div>
                <div className="input-container">
                    <label className="input-title">Coach</label>
                    <input 
                        type="text" 
                        value={coach}
                        onChange={(e) => setCoach(e.target.value)}/>
                </div>
                <div className="input-container">
                    <label className="input-title">Short name</label>
                    <input 
                        type="text" 
                        value={shortName}
                        onChange={(e) => setShortName(e.target.value)}/>
                </div>
                <div className="input-container">
                    <label className="input-title">Logo</label>
                    <div className="et-logo-and-btn" onClick={openUploadLogo}>
                        <div><img src={logoUrl} alt="logo"/></div>
                        <button type="button">Change logo</button>
                    </div>
                </div>
            </form>
            <div className="confirm-cancel-btn-cont full">
                <button 
                    type="button"
                    onClick={() => submitTeamEdit()}
                    className="green-btn">
                    Submit
                </button>
                <button 
                    type="button"
                    onClick={resetEdit}>
                    Cancel
                </button>
            </div>
        </div>
    );

    return (
        <CustomModal isOpen className="small-tall" onRequestClose={resetEdit}>
            {editTeam}
            {uploadLogo && (
                <UploadImage 
                    onClose={closeUploadLogo}
                    onChange={changeImage}/>
            )}
        </CustomModal>
    )
}

export default EditTeam;