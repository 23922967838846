import Config from "./Config"
import { useBackend } from "./Backend"
import { useLoginSession } from "../stores/loginSession"
import { useCheckIsAdmin } from "./UserGroups"

export function useGetCategories () {
    const [isFsAdmin,] = useCheckIsAdmin()
    if (Config.association === "SHL") return null
    if (!isFsAdmin) return Config.categories
    return {...Config.categories, ...Config.rollCategories}
}

export function useGetOfficialTags () {
    const {token} = useLoginSession()
    const query = {access_token: token}
    
    const {data} = useBackend("/tag", query)
    const officialTags = data?.tags || {}

    return officialTags
}