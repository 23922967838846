import React, {useState} from "react";
import VideoMetadata from "../../Library/EditPlaylist/VideoMetadata";
import StreamVideo from "./StreamVideo";
import Backend from "../../../utility/Backend";
import StreamMetadata from "./StreamMetadata";
import {useUpdateSearchParams} from "../../../utility/Utilities";
import {useLoginSession} from "../../../stores/loginSession";
import {useFeedbackMessage} from "../../../stores/FeedbackMessage";
import {useBackend} from "../../../utility/Backend";
import ConfirmModal from "../../../components/ConfirmModal";
import "./StreamDetails.css";
import "../Streams.css";
import {ImStop} from "react-icons/im";
import StreamGameDetails from "./StreamGameDetails";
import LoadingIcon from "../../../components/LoadingIcon";
import classNames from "classnames";
import { useMutateByRegex } from "../../../utility/Utilities";

export function streamDisplayDuration (duration) {
    if (isNaN(duration)) return "";
    let min = Math.floor(duration / 60000);
    let hour = Math.floor(min / 60);
    min %= 60;

    return (
        <>
            {hour > 0 && <div>{hour} {hour <= 1? "hour": "hours"}</div>}
            {min > 0 && <div>{min} mins</div>}
        </>
    );
}

function StreamDetails ({stoppingStream, setStoppingStream}) {

    const [searchParams] = useUpdateSearchParams();
    const {token} = useLoginSession();
    const {showFeedback} = useFeedbackMessage();
    const mutateByRegex = useMutateByRegex();

    const [isStopStreamOpen, setIsStopStreamOpen] = useState(false);

    const streamId = searchParams.get("stream");
    const apiQuery = {access_token: token, include_key: true}
    const {data: singleStream} = useBackend("/live_ingest/" + streamId, apiQuery);

    if (!singleStream) return null;

    const streamKey = singleStream.rtmp_stream_key || singleStream.srt_stream_key
    const streamValidFrom = streamKey.valid_from
    const streamValidTo = streamKey.valid_to
    const streamReadyForLive = new Date(streamValidFrom) < new Date() && new Date(streamValidTo) > new Date()
    const isLive = singleStream?.playlist?.is_live;
    const streamPlaylist = singleStream.playlist
    const livestreamId = singleStream.playlist.is_placeholder && streamId

    let streamApiUrl = "/rtmp_stream_key"
    if (singleStream.srt_stream_key) streamApiUrl = "/srt_stream_key"

    const refreshStreams = () => mutateByRegex(/^\/|(live_ingest)/)
    
    const stopStream = (streamId) => {
        const query = {
            access_token: token,
            stream_key: streamKey.id
        }

        Backend.post(streamApiUrl + "/end", {}, query)
            .then(({error}) => {
                if (error) {
                    console.error("Failed to DELETE", error);
                    showFeedback("warning", "Failed to stop stream, " + error);
                } else {
                    console.log("Stopping stream");
                    setStoppingStream(streamId)
                    setIsStopStreamOpen(false)
                    refreshStreams()
                }
            });
        return false;
    }

    const streamIsStopping = stoppingStream === singleStream.id
    
    const streamStatusBanner = (
        <div className={classNames("stream-status-banner", {"show": streamReadyForLive})}>
            {(isLive && !streamIsStopping) && (<div className="live-banner">LIVE</div>)}
            {(streamReadyForLive && !streamIsStopping) && (
                <div className="stop-stream">
                    <button type="button" onClick={() => setIsStopStreamOpen(true)} className="red-btn smaller-font-btn">
                        <ImStop className="icon-in-btn"/>
                        Stop Stream
                    </button>
                </div>
            )}
        </div>
    )

    const stopStreamModal = (
        <ConfirmModal 
            isOpen 
            onClose={() => setIsStopStreamOpen(false)} 
            onConfirm={() => stopStream(singleStream.id)} 
            confirmText = "Stop"
            >
            <div className="confirm-icon-message">
                <div className="confirm-icon"><ImStop/></div>
                <div className="confirm-title">Do you really want to stop "{streamPlaylist.description}" stream?</div>
            </div>
        </ConfirmModal>
    );

    return (
        <div className="stream-details-and-game">
            {streamStatusBanner}
            <StreamVideo singleStream={singleStream}/>
            <StreamMetadata singleStream={singleStream}/>
            <VideoMetadata playlist={streamPlaylist} hasEditPermission hasGrantPermission livestreamId={livestreamId}/>
            <StreamGameDetails streamPlaylist={streamPlaylist}/>
            {isStopStreamOpen && stopStreamModal}
        </div>
    )
}

export default StreamDetails;