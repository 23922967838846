import React, {useState, useEffect} from "react";
import CustomModal from "../../../components/CustomModal";
import Backend from "../../../utility/Backend";
import Config from "../../../utility/Config";
import {useLoginSession} from "../../../stores/loginSession";
import { useMutateByRegex } from "../../../utility/Utilities";
import { useFeedbackMessage } from "../../../stores/FeedbackMessage";
import UploadTimeOfRecordingSection from "../../UploadVideo/UploadTimeOfRecordingSection";
import StreamDuration from "../StreamComponents/StreamDuration";
import { getDateFormat } from "../../../utility/Utilities";
import { streamDisplayDuration } from "./StreamDetails";
import { showErrorMessage } from "../../../utility/Utilities"
import { useBackend } from "../../../utility/Backend";
import "./StreamDetails.css";

function EditStream ({ singleStream, onClose, isLive=false, streamApiUrl }) {

    const {token} = useLoginSession();
    const {showFeedback} = useFeedbackMessage();
    const mutateByRegex = useMutateByRegex();
    
    const stream = singleStream.rtmp_stream_key || singleStream.srt_stream_key
    
    const playlist = singleStream.playlist
    const isSef = Config.association === "SEF"
    const pricePlan = isSef && singleStream.playlist.payment?.price_plans[0]

    const {data: asset} = useBackend("/asset/" + playlist.events[0].video_asset_id, {access_token: token})

    const validToTimestamp = new Date(stream.valid_to).getTime();
    const streamBroadcastTimestamp = new Date(singleStream.broadcast_start).getTime();
    const createdStreamDuration = (validToTimestamp - streamBroadcastTimestamp) / 3600000

    const [broadcastStart, setBroadcastStart] = useState(singleStream.broadcast_start)
    const [duration, setDuration] = useState(createdStreamDuration);
    const [hasInputError, setHasInputError] = useState(false);
    const [startedIngestTime, setStartedIngestTime] = useState(null)
    
    // valid_from
    const broadcastStartMinute = new Date(broadcastStart).getMinutes();
    const broadcastMinusPreBroadcast = new Date(broadcastStart).setMinutes(broadcastStartMinute - 15)
    const checkValidFrom = startedIngestTime?
        // when there is ingest that has started, valid_from is the sooner time (the started ingest time or the new selected broadcast time minus 15 mins)
        Math.min(startedIngestTime.getTime(), new Date(broadcastMinusPreBroadcast).getTime())
        :
        // no ingest has started, valid_from is broadcast time minus 15 minutes
        new Date(broadcastMinusPreBroadcast).getTime() 
    const validFrom = new Date(checkValidFrom).toISOString()

    // valid_to
    const broadcastStartHour = new Date(broadcastStart).getHours();
    const streamValidityAfterDuration = new Date(broadcastStart).setHours(broadcastStartHour + duration);
    const validTo = new Date(streamValidityAfterDuration).toISOString()

    // values to check if ingest has started and if the broadcast is too long (more than 1 hour) from the started ingest time
    const ingestHasStarted = new Date(stream.valid_from) < new Date()
    const startedIngestMinute = new Date(stream.valid_from).getMinutes()
    const lastLimitFromIngest = new Date(stream.valid_from).setMinutes(startedIngestMinute + 75)
    const allowedTimeFromIngest = new Date(lastLimitFromIngest).toISOString()
    const broadcastTooLongFromIngest = ingestHasStarted && new Date(broadcastStart).getTime() > new Date(lastLimitFromIngest).getTime()

    // to check if the added time is too far behind (30 mins) from present time
    const pastSchedule = !isLive && (new Date(broadcastStart) - new Date()) < -1800000

    useEffect(() => {
        if (ingestHasStarted) setStartedIngestTime(new Date(stream.valid_from))
    }, [ingestHasStarted])

    useEffect(() => {
        if (pastSchedule) setHasInputError(true)
        else setHasInputError(false)
    }, [pastSchedule])

    useEffect(() => {
        if (broadcastTooLongFromIngest) setHasInputError(true)
        else setHasInputError(false)
    }, [broadcastTooLongFromIngest])

    const broadcastTimeChanged = singleStream.broadcast_start !== broadcastStart

    const updateBroadcastTime = (isoDate) => {
        setBroadcastStart(isoDate);
    }

    const resetStreamEdit = () => {
        setBroadcastStart(singleStream.broadcast_start);
        setDuration(createdStreamDuration);
        setStartedIngestTime(null);
        setHasInputError(false);
        onClose();
        showFeedback();
    }

    const submitStreamEdit = async () => {

        if (!duration || duration === 0) {
            setHasInputError(true);
            return
        }

        const query = {
            access_token: token,
            stream_key: stream.id,
        };

        const streamPutData = {
            valid_from: validFrom,
            valid_to: validTo,
        }

        console.log(JSON.stringify(streamPutData));

        Backend.put(streamApiUrl, JSON.stringify(streamPutData), query)
            .then(({error}) => {
                if (error) {
                    console.error("Failed to PUT", error);
                    showFeedback("warning", "Failed to edit stream, " + error);
                } else {
                    console.log("Stream details edited");
                    mutateByRegex(/^\/|(live_ingest)/)
                    resetStreamEdit();
                    showFeedback("success", "Stream edited successfully");
                }
            });

        if (broadcastTimeChanged) {

            const newBroadcastStart = {broadcast_start: broadcastStart}
            
            await Backend.put("/live_ingest/" + singleStream.id, JSON.stringify(newBroadcastStart), query)
                .then(({error}) => {
                    if (error) {
                        console.error("Failed to PUT", error);
                        showFeedback("warning", "Failed to edit stream, " + error);
                    } else {
                        console.log("Stream details edited");
                        mutateByRegex(/^\/|(live_ingest)/)
                        resetStreamEdit();
                        showFeedback("success", "Stream edited successfully");
                    }
                });
                
            if (playlist.is_placeholder) {
                const assetPutData = {timestamp: broadcastStart}
                let body = new FormData()
                body.append("params", JSON.stringify(assetPutData))
                console.log("Saving asset data:", JSON.stringify(assetPutData))
                await Backend.put("/asset/" + asset.id, body, query, {json: false})
                    .then(({error}) =>  {
                        if (error) {
                            console.error("Failed to PUT asset", error)
                            showFeedback("warning", "Failed to edit asset " +  error)
                        } else {
                            console.log("Asset edited");
                        }
                    })
            }
            
            if (pricePlan) {
                
                const originalBroadcast = new Date(singleStream.broadcast_start).getTime()
                const paymentRequiredUntil = new Date(playlist.payment.payment_required_until).getTime()
                const paymentPeriod = Math.ceil((paymentRequiredUntil - originalBroadcast) / 86400000)
                const newBroadcastTime = new Date(broadcastStart)
                const startPlusPaymentPeriod = newBroadcastTime.setDate(newBroadcastTime.getDate() + paymentPeriod)

                const playlistPutData = {
                    payment: {
                        payment_required_until: new Date(startPlusPaymentPeriod).toISOString(),
                        price_plans: [playlist.payment.price_plans[0]]
                    }
                }

                console.log("Saving playlist data:", JSON.stringify(playlistPutData))
                await Backend.put("/playlist/" + playlist.id, JSON.stringify(playlistPutData), query)
                    .then(({error}) =>  {
                        if (error) {
                            console.error("Failed to PUT playlist", error)
                            showFeedback("warning", "Failed to edit metadata, " +  error)
                        } else {
                            console.log("Playlist details edited");
                            mutateByRegex(/^\/playlist\//)
                            showFeedback("success", "Playlist edited successfully");
                        }
                    })
            }
        }
    }

    const editStreamForm = (
        <form className="edit-stream-form">
            {isLive && (<div className="live-banner">LIVE</div>)}
            <div className="input-container">
                <div className="input-title">Broadcast starts</div>
                {!isLive? (
                    <UploadTimeOfRecordingSection timestamp={new Date(broadcastStart)} onChange={updateBroadcastTime}/>
                ) : (
                    <div className="stream-schedule-active">{getDateFormat(singleStream.broadcast_start, true, true)}</div>
                )}
                {/* TODO better message */}
                <div className="stream-time-info margin-bottom">
                    ingest {ingestHasStarted? "started" : "will start"} at {getDateFormat(validFrom, true, true)}
                </div>
                {/* TODO better message */}
                {showErrorMessage("Cannot schedule stream in the past", pastSchedule)}
                {showErrorMessage("This stream has already started at " + getDateFormat(new Date(stream.valid_from).toISOString(), true, true), broadcastTooLongFromIngest)}
                {showErrorMessage("Broadcast time cannot be later than " + getDateFormat(allowedTimeFromIngest, true, true), broadcastTooLongFromIngest)}
            </div>
            <div className="input-container">
                <label htmlFor="" className="input-title">Maximum duration to reserve</label>
                <StreamDuration 
                    streamStartTime={broadcastStart} 
                    duration={duration} 
                    onChange={setDuration} 
                    createdStreamDuration={createdStreamDuration} 
                    hasInputError={hasInputError}
                    setHasInputError={setHasInputError}
                    isLive={isLive}/>
            </div>
            <div className="input-container">
                <label htmlFor="" className="input-title">Total ingest duration</label>
                <div className="sd-duration">{streamDisplayDuration(new Date(validTo) - new Date(validFrom))}</div>
            </div>
            <div className="input-container">
                <label htmlFor="" className="input-title">Broadcast ends</label>
                <div>{getDateFormat(validTo, true, true)}</div>
            </div>
        </form>
    )

    return (
        <CustomModal isOpen onRequestClose={resetStreamEdit} className="medium">
            <div className="edit-stream-cont">
                <div className="edit-stream-title">Edit Stream</div>
                {editStreamForm}
                <div className="confirm-cancel-btn-cont right">
                    <button 
                        type="button"
                        disabled={hasInputError}
                        onClick={submitStreamEdit}
                        className="green-btn">
                        Submit
                    </button>
                    <button 
                        type="button"
                        onClick={resetStreamEdit}>
                        Cancel
                    </button>
                </div>
            </div>
        </CustomModal>
    )
}

export default EditStream;