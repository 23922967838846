import React from "react";
import Config from "../../../utility/Config";
import { useBackend, generateHref } from "../../../utility/Backend";
import { useLoginSession } from "../../../stores/loginSession";
import { getDateFormat } from "../../../utility/Utilities";
import { Paging } from "../../../utility/Paging";
import { LoadingPage } from "../../../components/Page";
import {useUpdateSearchParams} from "../../../utility/Utilities";
import "../Streams.css";
import "./RenderStreamsMobile.css";

function RenderStreamsMobile () {

    const [searchParams, updateSearchParams, resetAllSearchParamsExcept] = useUpdateSearchParams();
    const {token} = useLoginSession();

    const streamTypeParam = searchParams.get("type") || "scheduled"
    const streamPageParam = searchParams.get("page")
    const leagueParams = searchParams.get("league") || undefined
    
    const streamsPerPage = 6
    const page = streamPageParam ? parseInt(streamPageParam) : 1
    const from = streamPageParam ? (page-1) * streamsPerPage : 0

    const tenDaysAgo = new Date()
    tenDaysAgo.setDate(tenDaysAgo.getDate() - 10)
    tenDaysAgo.setMinutes(0, 0, 0)

    const fetchStreamProps = {
        access_token: token,
        asc: true,
        from: from,
        count: streamsPerPage,
        all_leagues: leagueParams ? undefined : true,
        status: streamTypeParam,
    }
    
    if (streamTypeParam === "scheduled") fetchStreamProps.from_date = tenDaysAgo.toISOString()
    if (streamTypeParam === "finished") fetchStreamProps.asc = false

    const liveIngestApiPath = generateHref("/live_ingest/editable", leagueParams)
    const {data: streamsData} = useBackend(liveIngestApiPath, fetchStreamProps, {
        refreshInterval: 10000,
    });

    const streamListData = streamsData?.live_ingests || []
    const totalStreams = streamsData?.total
    const totalPage = Math.ceil(totalStreams / streamsPerPage)
    const isSef = Config.association === "SEF"

    if (!streamsData) return <LoadingPage/>

    const onShowStream = (streamId) => {
        updateSearchParams({"stream": streamId})
    }

    const onChangePage = (page) => {
        if (page > 1) updateSearchParams({"page": page})
        else resetAllSearchParamsExcept(["type", "stream"])
    }

    if (streamListData.length === 0) return (
        <div className="no-streams">No {streamTypeParam} streams</div>
    )
    
    return (
        <>
            <div className="stream-list-mobile-cont">
                <div className="stream-list-mobile-header">
                    <div>Title</div>
                    <div>Broadcast start</div>
                </div>
                {streamListData.map((s) => {

                    const {playlist} = s
                    const channelTeamLogo = isSef && playlist.channels[0]?.team?.logo_url

                    const channelTeamBackgroundLogo = channelTeamLogo && (
                        <div className="mobile-stream-team-bg">
                            <img src={channelTeamLogo} alt="team logo"/>
                        </div>
                    )

                    return (
                        <div key={s.id} onClick={() => onShowStream(s.id)} className="stream-list-mobile-data">
                            {channelTeamBackgroundLogo}
                            <div className="stream-list-mobile-title">
                                <div className="stream-list-mobile-description">{playlist.description}</div>
                                {playlist.is_private ? <div className="text-yellow">Not public</div> : null}
                            </div>
                            <div className="stream-list-mobile-broadcast">
                                {getDateFormat(s.broadcast_start, true, true)}
                                {playlist.is_live && (<div className="live-banner">LIVE</div>)}
                            </div>
                        </div>
                    )
                })}
            </div>
            <Paging page={page} pageCount={totalPage} onChange={onChangePage}/>
        </>  
    )
}


export default RenderStreamsMobile