import "./Login.css";
import React, {useState} from "react";
import { FaUser, FaLock } from "react-icons/fa";
import {useLoginSession} from "../../stores/loginSession";
import Page from "../../components/Page";
import {Helmet} from "react-helmet-async";
import Config from "../../utility/Config";
import LoadingScreen from "../../components/LoadingScreen";
import {useGetLeagueLogo} from "../../utility/useGetLeagueLogo";

function LoginScreenLogoAndName ({league}) {
    
    const [associationLogo, ,displayName] = useGetLeagueLogo(league)
    
    let systemName = "Media Asset Management"

    const sefAssociations = ["allsvenskan", "superettan", "demo-tomas-l1", "demo-tomas-l2"]
    if (sefAssociations.includes(league)) systemName = "Backoffice"

    return (
        <h1>
            <img src={associationLogo} alt="logo" />
            <br />
            {displayName}
            <br />
            {systemName}
        </h1>
    )
}

export default function Login () {
    
    const {loginIsLoading, error, logIn} = useLoginSession();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isFormVisible, setIsFormVisible] = useState(!Config.fotbollkontoLoginEnabled);

    const baseName = window.location.pathname.split("/")[1];

    function onSubmit (e) {
        e.preventDefault();
        logIn(username, password);
        return false;
    }

    function onClickFotbollkonto () {
        const {pathname, search} = window.location;
        const locationState = encodeURIComponent(pathname + search);
        const ssoAuthorizePath = "/user/fotbollkonto/authorize?state=" + locationState;
        window.location = Config.getBackend() + ssoAuthorizePath;
    }

    if (loginIsLoading) return (
        <Page centered>
            <Helmet>
                <title>Login</title>
            </Helmet>
            <div className="login-container flex-centered">
                <LoadingScreen />
            </div>
        </Page>
    )

    let usernameLoginForm = isFormVisible ? (
        <>
            <div>
                <label htmlFor="login-username"><FaUser /></label>
                <input id="login-username"
                       value={username}
                       onChange={(e) => setUsername(e.target.value)}
                       placeholder="Username" />
            </div>
            <div>
                <label htmlFor="login-password"><FaLock /></label>
                <input id="login-password"
                       type="password"
                       value={password}
                       onChange={(e) => setPassword(e.target.value)}
                       placeholder="Password" />
            </div>
            <div>
                <button type="submit" >
                    Log in
                </button>
            </div>
        </>
    ) : (
        <div className="login-with-username-link" onClick={() => setIsFormVisible(true)}>
            Log in for admins
        </div>
    )

    return (
        <Page centered>
            <Helmet>
                <title>Login</title>
            </Helmet>
            <div className="login-container flex-centered">
                <form onSubmit={onSubmit}>
                    <LoginScreenLogoAndName league={baseName}/>
                    <div className="error-msg">
                        {error}
                    </div>
                    {Config.fotbollkontoLoginEnabled && (
                        <div className="login-fotbollkonto-container">
                            <button type="button" onClick={onClickFotbollkonto}>
                                Log in via Fotbollkonto
                            </button>
                        </div>
                    )}
                    <div className="login-separator"></div>
                    {usernameLoginForm}
                    <div className="login-build-version-text">
                        Version: {Config.buildVersion}
                    </div>
                </form>
            </div>
        </Page>
    );
}