import React from "react";
import PermissionModal from "./PermissionModal"
import Backend from "../../../utility/Backend";
import { useBackend } from "../../../utility/Backend";
import { useLoginSession } from "../../../stores/loginSession";
import { useFeedbackMessage } from "../../../stores/FeedbackMessage";
import { useCheckIsAdmin, useGetUsersGroups } from "../../../utility/UserGroups";
import "../../../components/css/Permission.css";

function Permission ({
    playlist, 
    onClose,
}) {
    
    const {token} = useLoginSession()
    const query = {access_token: token}
    const {showFeedback} = useFeedbackMessage()
    const [,isAdmin] = useCheckIsAdmin()
    const usersUserGroups = useGetUsersGroups()

    const {data: permittedUserGroups, mutate} = useBackend(`/playlist/${playlist.id}/usergroup`, query)
    const {data: userGroups} = useBackend("/usergroup", query)

    if (!permittedUserGroups || !userGroups) return null
    
    // filter and sort specific type of permitted user groups
    const sortPermittedGroups = (type) => {
        let permission = type
        if (type === "view") permission = "playlist_view"
        if (type === "edit") permission = "playlist_edit"
        if (type === "grant") permission = "playlist_grant"

        return permittedUserGroups.usergroups
            .filter((group) => group.permission === permission)
            .map((group) => {
                return {
                    id: group.usergroup.id,
                    name: group.usergroup.usergroup,
                    permission: [group.permission],
                    logo_url: group.usergroup.logo_url,
                }
            })
    }

    const viewingGroups = sortPermittedGroups("view")
    const editingGroups = sortPermittedGroups("edit")
    const grantingGroups = sortPermittedGroups("grant")

    const allPermittedGroups = editingGroups.concat(viewingGroups).concat(grantingGroups)
    const permittedUsersId = allPermittedGroups.map(group => group.id)
    
    // check and filter the same user group that has two or more permission
    // final list of user groups with permissions
    const uniquePermittedId = []
    let combinedPermittedList = []

    for (let i=0; i < allPermittedGroups.length; i++){
        if (!uniquePermittedId.includes(allPermittedGroups[i].id)) {
            uniquePermittedId.push(allPermittedGroups[i].id)
            combinedPermittedList.push({
                id: allPermittedGroups[i].id,
                name: allPermittedGroups[i].name,
                permission: allPermittedGroups[i].permission,
                logo_url: allPermittedGroups[i].logo_url,
            })
        }
        else {
            for (let e=0; e < combinedPermittedList.length; e++) {
                if (combinedPermittedList[e].id === allPermittedGroups[i].id) {
                    combinedPermittedList[e].permission.push(allPermittedGroups[i].permission[0])
                }
            }
        }
    }

    // sort user groups that have most permissions on top of the table
    combinedPermittedList.sort(function (a, b) {
        const groupA = a.permission.length
        const groupB = b.permission.length
        return (groupA > groupB)? -1 : (groupA < groupB)? 1:0
    })

    // filter and sort user groups that have no permissions
    const filteredUserGroups = userGroups.usergroups
        .filter(group => group.name !== ("fs_admin"))
        .filter(group => group.name !== ("sef_admin"))
        .filter(group => !permittedUsersId.includes(group.id))
        .map(group => {
            return {
                id: group.id,
                name: group.name,
                permission: undefined,
                logo_url: group.logo_url,
            }
        })
    
    // final list of user groups with no permissions
    let unPermittedList = filteredUserGroups
    
    // check if the user has the last granting usergroup in the permission list
    let userGroupsWithGrantAccess = []
    for (let i = 0; i < usersUserGroups.length; i++) {
        for (let idx = 0; idx < grantingGroups.length; idx++) {
            if (usersUserGroups[i].name === grantingGroups[idx].name) {
                userGroupsWithGrantAccess.push(usersUserGroups[i])
            }
        }
    }
    const lastGrantAccess = !isAdmin && userGroupsWithGrantAccess.length === 1
    
    const removePermission = (group, level) => {
        Backend.delete("/playlist/" + playlist.id + "/usergroup/" + group.id + level, query)
            .then(({error}) => {
                if (error) {
                    console.error("Failed to DELETE", error)
                    showFeedback("warning", "Failed to remove permission, " + error)
                } else {
                    console.log("usergroup permission removed")
                    mutate(permittedUserGroups)
                }
            });
    }

    const givePermission = (group, level) => {
        Backend.post("/playlist/" + playlist.id + "/usergroup/" + group.id + level, {}, query)
            .then(({error}) => {
                if (error) {
                    console.error("Failed to POST", error)
                    showFeedback("warning", "Failed to edit permission, " + error)
                } else {
                    console.log("usergroup permission edited")
                    mutate(permittedUserGroups)
                }
            });
    }

    const editPermission = (group, type, remove=false) => {
        let level
        if (type === "viewing") level = "/view"
        if (type === "editing") level = "/edit"
        if (type === "granting") level = "/grant"

        if (remove) {
            removePermission(group, level)
            return
        }
        else {
            givePermission(group, level)
        }
    }

    return (
        <PermissionModal
            playlist={playlist}
            combinedPermittedList={combinedPermittedList}
            unPermittedList={unPermittedList}
            editPermission={editPermission}
            lastGrantAccess={lastGrantAccess}
            onClose={onClose}
            />
    )
}

export default Permission