import "./css/Page.css";
import LoadingScreen from "./LoadingScreen";
import {useLocalStorage, useUpdateSearchParams} from "../utility/Utilities";
import {IoArrowBack} from "react-icons/io5";
import {RiLayoutColumnLine, RiLayoutLeftLine, RiLayoutRightLine} from "react-icons/ri";

function Page ({
    children,
    title=null,
    width=0,
    folded=false,
    centered=false,
    ...props
}) {

    let style = {};
    if (width) {
        const w = isNaN(width) ? width : `${width}px`;
        style = {flex: "0 0 auto", width: w}
    }

    let containerCls = "page-container";
    if (folded) containerCls += " folded"

    let contentCls = "page-content";
    if (centered) contentCls += " flex-centered";

    return (
        <div className={containerCls} style={style} {...props} >
            <div className="page-title">
                {title}
            </div>
            <div className={contentCls}>
                {children}
            </div>
        </div>
    );
}

export function DualPage ({
    children,
    className,
    rightPageQueryParam,
    withLayoutSelection,
    defaultLayout,
}) {
    let cls = "dual-page-container";
    if (className) cls += " " + className;

    const [searchParams, updateSearchParam] = useUpdateSearchParams();
    const rightPageEnabled = searchParams.get(rightPageQueryParam) !== null;
    if (rightPageEnabled) cls += " page-right-active";

    function backBtnClicked () {
        updateSearchParam({[rightPageQueryParam]: null});
    }

    if (defaultLayout === undefined)
        defaultLayout = (withLayoutSelection && window.screen.width > 1800) ? 2 : 1;

    const [layout, setLayout] = useLocalStorage("page_layout_" + defaultLayout, defaultLayout, null);
    const layoutClass = ["page-layout-left", null, "page-layout-right"][layout];
    if (layoutClass) cls += " " + layoutClass;
    let layoutButtons = null;
    if (withLayoutSelection) {
        cls += " with-layout-buttons"
        layoutButtons = (
            <div className="page-layout-buttons">
                {rightPageEnabled && (
                    <button className="active icon-button back-button" onClick={backBtnClicked}>
                        <IoArrowBack/> Back
                    </button>
                )}
                <button className={layout === 0 ? "icon-button active" : "icon-button"}
                onClick={() => setLayout(0)}>
                    <RiLayoutRightLine/>
                </button>
                <button className={layout === 1 ? "icon-button active" : "icon-button"}
                        onClick={() => setLayout(1)}>
                    <RiLayoutColumnLine/>
                </button>
                <button className={layout === 2 ? "icon-button active" : "icon-button"}
                        onClick={() => setLayout(2)}>
                    <RiLayoutLeftLine/>
                </button>
            </div>
        );
    }

    return (
        <div className={cls}>
            {children}
            {layoutButtons}
        </div>
    )
}

export function LoadingPage (props) {
    return (
        <Page {...props} >
            <div className="is-loading flex-vertically-centered">
                <LoadingScreen />
            </div>
        </Page>
    )
}

export function ErrorPage ({children, ...props}) {
    return (
        <Page {...props} >
            <div className="is-loading error-msg flex-vertically-centered">
                {children}
            </div>
        </Page>
    )
}

export default Page;