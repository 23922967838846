import React, {useState, useRef, useEffect} from "react"
import UploadCategorySection from "../../../UploadVideo/UploadCategorySection";
import UploadChannelSection from "../../../UploadVideo/UploadChannelSection";
import UploadPrePostRollSection from "../../../UploadVideo/UploadPrePostRollSection";
import ThumbnailSelector from "../../../../components/ThumbnailSelector";
import {showErrorMessage} from "../../../../utility/Utilities";
import classNames from "classnames";
import "./EditingControls.css";
import {FiCheckSquare, FiSquare} from "react-icons/fi";
import {BsInfoCircle} from "react-icons/bs";

function NewSubclipForm ({
    formState,
    dispatchFormData,
    onSave, 
    onDiscard,
    playlistClips,
}) {

    const titleRef = useRef(null)

    // in state because these two are not in the parameters block in formState
    const [tags, setTags] = useState([])
    const [thumbnailImage, setThumbnailImage] = useState(null)
    const [hasInputError, setHasInputError] = useState(false)

    const setThumbnail = (newUrl) => {
        if (typeof newUrl === "object") {
            setThumbnailImage(newUrl.imageFile)
            dispatchFormData("thumbnail_url", newUrl.imageUrl)
        }
        else dispatchFormData("thumbnail_url", newUrl)
    }

    const invalidTitle = formState.description === "" || formState.description.length < 5 || formState.description.length > 90

    useEffect(() => {
        if (hasInputError && !invalidTitle) setHasInputError(false)
    }, [hasInputError, invalidTitle])

    const saveSubclip = () => {
        if (invalidTitle) {
            setHasInputError(true)
            if (titleRef.current) {
                titleRef.current.scrollIntoView()
            }
            return
        }
        onSave(tags, thumbnailImage)
    }

    return (
        <>
            <div className="confirm-cancel-btn-cont between margin-top">
                <button onClick={saveSubclip} className="green-btn">Save subclip</button>
                <button onClick={onDiscard}>
                    Discard
                </button> 
            </div>
            <form className="new-subclip-form" onSubmit={(e) => e.preventDefault()}>
                <div ref={titleRef} className="input-container">
                    <label className="input-title">Title</label>
                    <textarea
                        className="listing-filter-textbox"
                        placeholder="Min 5 characters and max 90 characters"
                        rows="2"
                        value={formState.description}
                        onChange={(e) => dispatchFormData("description", e.target.value)}>
                    </textarea>
                </div>
                {hasInputError && showErrorMessage("Title must be min 5 characters and max 90 characters", invalidTitle)}
                <div className="input-container">
                    <label className="input-title">Channels</label>
                    <UploadChannelSection
                        selectedChannel={formState.channels}
                        setSelectedChannel={(channel) => dispatchFormData("channels", channel)}
                    />
                </div>
                <div className="input-container">
                    <label className="input-title">Category</label>
                    <UploadCategorySection
                        tags={tags}
                        setTags={setTags}
                    />
                </div>
                <div className="input-container">
                    <label className="input-title">Published</label>
                    <>
                        <div
                            onClick={() => dispatchFormData("is_private", true)}
                            className={classNames("option-checkbox", {"active": formState.is_private})}>
                            {formState.is_private?
                                <FiCheckSquare className="check-box-icon"/> :
                                <FiSquare className="check-box-icon"/>}
                            <div className="option-box-title">
                                Not yet published
                                <div className="option-extra-message">
                                    <BsInfoCircle className="info-icon"/> video will be unlisted (can be changed later)
                                </div>
                            </div>
                        </div>
                        <div
                            onClick={() => dispatchFormData("is_private", false)}
                            className={classNames("option-checkbox", {"active": !formState.is_private})}>
                            {!formState.is_private?
                                <FiCheckSquare className="check-box-icon"/> :
                                <FiSquare className="check-box-icon"/>}
                            <div className="option-box-title">
                                Published
                            </div>
                        </div>
                    </>
                </div>
                <div className="input-container">
                    <label className="input-title">Thumbnail</label>
                    <ThumbnailSelector
                        selected={formState.thumbnail_url}
                        onChange={setThumbnail}
                        playlistClips={playlistClips}
                        />
                </div>
                <div className="input-container">
                    <label className="input-title">Pre-roll</label>
                    <UploadPrePostRollSection selected={formState.prerolls} setSelected={(p) => dispatchFormData("prerolls", p)}/>
                </div>
                <div className="input-container">
                    <label className="input-title">Post-roll</label>
                    <UploadPrePostRollSection selected={formState.postrolls} setSelected={(p) => dispatchFormData("postrolls", p)} postRoll/>
                </div>
                <div className="confirm-cancel-btn-cont between">
                    <button onClick={saveSubclip} className="green-btn">
                        Save subclip
                    </button>
                    <button onClick={onDiscard}>
                        Discard
                    </button>
                </div>
            </form>
        </>
    )
}

export default NewSubclipForm