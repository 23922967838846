import { useVideoPlayer } from "../../../components/VideoPlayer/VideoPlayerProvider"
import Config from "../../../utility/Config";
import { hockeyTimeFormat } from "../../../utility/Utilities";
import classNames from "classnames"
import { tagToIconTimeline, tagToIconShlTimeline } from "../../../utility/Utilities"

function getTimelineActionIcon (tag, hockey=false) {
    if (hockey) return tagToIconShlTimeline(tag)
    else return tagToIconTimeline(tag)
}

export function RenderEvent ({event, startTime, endTime, index, side}) {

    const {
        playing, 
        setPlaying, 
        videoProgress, 
        seekTo
    } = useVideoPlayer()

    const isShl = Config.association === "SHL"
    const playlistEvent = event.playlist.events[0]
    const game = event.playlist.game
    
    const away = side === "away"
    const isGoal = event.tag.action === "goal"
    const gameTime = isShl? hockeyTimeFormat(event.tag) : Math.ceil(event.game_time / 60)
    const teamLogo = away? game.visiting_team.logo_url : game.home_team.logo_url

    const percentage = ((playlistEvent.from_timestamp - startTime) / (endTime - startTime)) * 100
    const isPenaltyShot = event.tag.action === "shootoutpenaltyshot"

    const onClickToEvent = async () => {
        if (!playing && videoProgress === 0) await setPlaying(true)
        seekTo((playlistEvent.from_timestamp - startTime) / 1000)
    }
    
    // INLINE STYLING
    let eventVerticalGap = 0
    // same minute event
    // second, fifth, eighth video will be on the second row of 3 rows
    const secondRowEvent = [1,4,7]
    // third, sixth, ninth video will be on the third row of 3 rows
    const thirdRowEvent = [2,5,8]

    if (secondRowEvent.includes(index)) eventVerticalGap = 25
    if (thirdRowEvent.includes(index)) eventVerticalGap = 50

    let style = {
        left: `${percentage}%`,
        top: `${50-eventVerticalGap}px`
    }

    if (away) {
        style = {
            left: `${percentage}%`,
            top: "unset",
            bottom: `${57-eventVerticalGap}px`
        }
    }

    const iconLineHeight = `${15+eventVerticalGap}px`

    return (
        <div 
            onClick={onClickToEvent} 
            className={classNames("timeline-game-icon", {
                "away": away,
                "penalty-shot": isPenaltyShot,
                "timeline-start": percentage < 4,
                "timeline-end": percentage > 96,
            })}
            style={style}
            >
            {isGoal? (
                <div className="timeline-score">
                    <div className="score-number">{event.score}</div>
                </div>
            ) : (getTimelineActionIcon(event.tag.action, isShl))}
            <div className="icon-line" style={{height: iconLineHeight}}></div>
            <div className="timeline-hover-info">
                <div className="timeline-hover-title">
                    <div className="timeline-hover-time-icon">  
                        <div>{gameTime}'</div>
                        {isGoal? event.score : getTimelineActionIcon(event.tag.action, isShl)}
                    </div>
                    <img src={teamLogo} alt="logo"/>
                </div>
                <div className="timeline-single-desc">
                    {event.playlist.description}
                </div>
            </div>
        </div>
    )
}

export function RenderPhaseEvent ({event, startTime, endTime}) {

    const {
        playing, 
        setPlaying, 
        videoProgress, 
        seekTo
    } = useVideoPlayer()

    if (!event.playlist) return null

    const playlistEvent = event.playlist.events[0]

    // TODO temporary or might be permanent clickable phase event
    const onClickToEvent = async () => {
        if (!playing && videoProgress === 0) await setPlaying(true)
        // TODO in football, must minus start time in fromTimestamp and endTime
        seekTo((playlistEvent.from_timestamp - startTime) / 1000)
    }

    // TODO in football, must minus start time in fromTimestamp and endTime
    const percentage = ((playlistEvent.from_timestamp - startTime) / (endTime - startTime)) * 100 
    const endPhase = event.tag.action === "end phase" || event.tag.action === "end of game"
    const phasePosition = {left: `${percentage}%`}

    let phaseType = "start"
    let phase = ""

    if (endPhase) phaseType = "end"
    
    switch (event?.tag?.phase?.value) {
        // football
        case "1st half":
            phase = "1st half"
            break;
        case "2nd half":
            phase = "2nd half"
            break;
        // hockey
        case "1st period":
            phase = "P1"
            break;
        case "2nd period":
            phase = "P2"
            break;
        case "3rd period":
            phase = "P3"
            break;
        case "penalty":
            phase = "PE"
            break;
        case "1st overtime":
            phase = "OT"
            break;
        case "shootout":
            phase = "SO"
            break;
        default:
            break;
    }
    
    const phaseName = `${phase} ${phaseType}`

    return (
        <div
            onClick={onClickToEvent} 
            className={classNames("timeline-phase-single", {
                "end": endPhase,
                "timeline-end": percentage > 96,
            })}
            style={phasePosition}
            >
            <div className="timeline-phase-text">{phaseName}</div>
        </div>
    )
}