import React, {useState} from "react"
import CustomModal from "../../../components/CustomModal";
import ConfirmModal from "../../../components/ConfirmModal";
import {AiOutlineExclamationCircle} from "react-icons/ai";
import {FaUser} from "react-icons/fa";
import {FiCheckSquare} from "react-icons/fi";
import {BsSquare} from "react-icons/bs";
import {BiSort} from "react-icons/bi";

function PermissionModal ({
    playlist,
    combinedPermittedList,
    unPermittedList,
    editPermission,
    lastGrantAccess=false,
    onClose,
}) {
    
    const [searchGroup, setSearchGroup] = useState("")
    const [ascending, setAscending] = useState(true)
    const [lastEditAccessWarning, setLastEditAccessWarning] = useState(false)
    const [lastGrantingGroup, setLastGrantingGroup] = useState(null)

    const ascendingSort = (a, b) => a.name.localeCompare(b.name)
    const descendingSort = (a, b) => b.name.localeCompare(a.name)

    combinedPermittedList.sort(ascendingSort)
    unPermittedList.sort(ascendingSort)

    if (!ascending) {
        combinedPermittedList.sort(descendingSort)
        unPermittedList.sort(descendingSort)
    }

    if (searchGroup !== "") {
        combinedPermittedList = combinedPermittedList.filter((group) => group.name.toLowerCase().includes(searchGroup.toLowerCase()));
        unPermittedList = unPermittedList.filter((group) => group.name.toLowerCase().includes(searchGroup.toLowerCase()));
    }

    const removeGranting = (group) => {
        if (lastGrantAccess) {
            setLastEditAccessWarning(true)
            setLastGrantingGroup(group)
            return 
        }
        else editPermission(group, "granting", "remove")
    }

    const confirmRemoveGranting = () => {
        editPermission(lastGrantingGroup, "granting", "remove")
        onClose()
    }

    const lastEditAccessModal = (
        <ConfirmModal
            isOpen
            onClose={() => setLastEditAccessWarning(false)}
            onConfirm={confirmRemoveGranting}
            cancelText="Cancel"
            confirmText="Remove">
            <div className="confirm-icon-message">
                <div className="confirm-icon"><AiOutlineExclamationCircle/></div>
                <div className="confirm-title">Are you sure you want to remove yourself from the last granting access?</div>
            </div>
        </ConfirmModal>
    )

    const renderUserGroupsList = (list) => {

        const renderedList = list.map((group) => {

            const view = group.permission?.includes("playlist_view")
            const edit = group.permission?.includes("playlist_edit")
            const grant = group.permission?.includes("playlist_grant")
            const publicView = group.name === "public_view"

            return (
                <div key={group.id} className="user-permission-single">
                    <div className="permission-user-group">
                        <div className="permission-list-user">
                            <div className="permission-list-profile">
                                {group.logo_url? (<img src={group.logo_url} alt="logo"/>) : 
                                (<div><FaUser/></div>)}
                            </div>
                            {group.name}
                        </div>
                    </div>
                    <div className="user-permission-type">
                        {view? 
                            <FiCheckSquare onClick={() => editPermission(group, "viewing", "remove")} className="permission-check-icon"/> : 
                            <BsSquare onClick={() => editPermission(group, "viewing")} className="permission-uncheck-icon"/>
                        }
                    </div>
                    <div className="user-permission-type">
                        {!publicView && (
                            <>
                                {edit? 
                                    <FiCheckSquare onClick={() => editPermission(group, "editing", "remove")} className="permission-check-icon"/> : 
                                    <BsSquare onClick={() => editPermission(group, "editing")} className="permission-uncheck-icon"/>
                                }
                            </>
                        )}
                    </div>
                    <div className="user-permission-type">
                        {!publicView && (
                            <>
                                {grant? 
                                    <FiCheckSquare onClick={() => removeGranting(group)} className="permission-check-icon"/> : 
                                    <BsSquare onClick={() => editPermission(group, "granting")} className="permission-uncheck-icon"/>
                                }
                            </>
                        )}
                    </div>
                </div>
            )
        })
        return renderedList
    }

    return (
        <CustomModal isOpen onRequestClose={onClose}>
            <div className="permission-cont">
                <div className="permission-clip-title">
                    <img src={playlist.thumbnail_url} alt="thumbnail"/>
                    {playlist.description}
                </div>
                <div className="available-permission-search">
                    Search
                    <input 
                        type="text" 
                        value={searchGroup}
                        onChange={(e) => setSearchGroup(e.target.value)}/>
                </div>
                <div className="user-permission-cont scrollable">
                    <div className="table-header-cont sticky">
                        <div onClick={() => setAscending(!ascending)} className="permission-user-group table-header-sort">
                            User group <BiSort className="table-header-sort-icon"/>
                        </div>
                        <div className="user-permission-type">Viewing</div>
                        <div className="user-permission-type">Editing</div>
                        <div className="user-permission-type">Granting</div>
                    </div>
                    {renderUserGroupsList(combinedPermittedList)}
                    {combinedPermittedList.length !== 0 && <br /> }
                    {renderUserGroupsList(unPermittedList)}
                </div>
                <button type="button" onClick={onClose} className="permission-close-btn">Close</button>
            </div>
            {lastEditAccessWarning && lastEditAccessModal}
        </CustomModal>
    )
}

export default PermissionModal