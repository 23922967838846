import React, {useState, useEffect} from "react"
import classNames from "classnames";
import { PiArrowFatLinesUpBold } from "react-icons/pi";
import { GiCheckMark } from "react-icons/gi";
import { TbSquareArrowUp, TbSquareArrowDown, TbSquareArrowRight } from "react-icons/tb";
import "./Search.css"

function RenderSuggestions ({title, suggestionList, searchProp, onSelect, setSuggestionIndex, selectingSuggestion=false, games=false}) {

    if (!suggestionList) return null
    
    return (
        <div>
            <div className="result-title">{title}</div>
            {suggestionList?.length !== 0 ? (
                <>
                    {suggestionList.map((s) => {
                        const suggestionSelected = searchProp.find((prop) => prop.id === s.id )
                        return (
                            <div 
                                key={s.id}
                                onMouseOver={() => setSuggestionIndex(null)}
                                onClick={() => onSelect(s)}
                                className={classNames("result-item", {"in-selection": s === selectingSuggestion})}
                                >
                                {s.name}
                                {suggestionSelected && <GiCheckMark/>}
                                {games && <div className="suggestion-game-date">{s.date}</div>}
                            </div>
                        )
                    })}
                </>
            ) : (<div className="suggestion-no-results">No results</div>)}
        </div>
    )
}

function SearchSuggestions ({
    resultSuggestions = [], 
    handleSelectSearchProp, 
    submitSearch, 
    searchInput, 
    searchProp, 
    setSearchProp, 
    suggestionListOpen, 
    setSuggestionListOpen
}) {

    const [suggestionIndex, setSuggestionIndex] = useState(null)
    
    const searchFilled = searchInput.split(" ").join("").length >= 3

    useEffect(() => {
        if (!searchInput && searchProp.length === 0) setSuggestionIndex(null)
        if (!suggestionListOpen) setSuggestionIndex(null)
    }, [searchInput, searchProp, suggestionListOpen])

    useEffect(() => {
        setSuggestionIndex(null)
    }, [resultSuggestions])

    const onKeyPress = (e) => {

        // arrow down key
        if (e.keyCode === 40) {
            if (suggestionListOpen) {
                if (suggestionIndex === null) {
                    setSuggestionIndex(0)
                } else if (suggestionIndex + 1 === allSuggestionsResults.length) {
                    setSuggestionIndex(0)
                } else {
                    setSuggestionIndex(suggestionIndex + 1)
                } 
            }
        }

        // arrow up key
        if (e.keyCode === 38) {
            if (suggestionListOpen) {
                if (suggestionIndex === null || suggestionIndex === 0) {
                    setSuggestionIndex(allSuggestionsResults.length - 1) 
                } else {
                    setSuggestionIndex(suggestionIndex - 1)
                } 
            }
        }

        // arrow right key
        if (e.keyCode === 39) {
            if (suggestionListOpen && selectingSuggestion) {
                handleSelectSearchProp(selectingSuggestion) 
            }
        }

        // backspace key
        if (e.keyCode === 8) {
            if (!searchInput && searchProp.length !== 0) {
                const lastProp = searchProp[searchProp.length-1]
                const updatedList = searchProp.filter((p) => p !== lastProp)
                setSearchProp(updatedList);
            }
        }
        
        // enter key
        if (e.keyCode === 13) {
            if (searchProp.length !== 0 || searchFilled) {
                submitSearch()
            }
        }
        // escape key
        if (e.keyCode === 27) {
            if (suggestionListOpen) {
                setSuggestionListOpen(false)
            }
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", onKeyPress);
        return () => {
            window.removeEventListener("keydown", onKeyPress);
        }
    })

    const suggestionActions = resultSuggestions?.actions?.slice(0,5)
        .map((a, idx) => {
            return {
                tag: "action",
                id: a.action,
                name: a.action
            }
        }) || []

    const suggestionPlayers = resultSuggestions?.players?.slice(0,5)
        .map((p) => {
            return {
                tag: "player",
                id: p.id,
                name: p.name,
            }
        }) || []
    
    const suggestionTeams = resultSuggestions?.teams?.slice(0,5)
        .map((t) => {
            return {
                tag: "team",
                id: t.id,
                name: t.name,
            }
        }) || []
    
    const suggestionGames = resultSuggestions?.games?.slice(0,5)
        .map((g) => {
            return {
                tag: "game",
                id: g.id,
                name: g.home_team + " vs " + g.visiting_team,
                date: g.event_date,
            }
        }) || []
    
    const allSuggestionsResults = suggestionActions.concat(suggestionPlayers).concat(suggestionTeams).concat(suggestionGames)
    const selectingSuggestion = allSuggestionsResults[suggestionIndex] || null
    
    const suggestionsTypes = ["actions", "players", "teams", "games"]

    return (
        <div className="result-suggestion-cont">
            <div className="search-keyboard-tips">
                Keyboard arrow 
                <TbSquareArrowUp/>
                <TbSquareArrowDown/>
                to move selection and 
                <TbSquareArrowRight/>
                to select
            </div>
            {suggestionsTypes.map((type) => {

                let suggestionList
                if (type === "actions") suggestionList = suggestionActions
                if (type === "players") suggestionList = suggestionPlayers
                if (type === "teams") suggestionList = suggestionTeams
                if (type === "games") suggestionList = suggestionGames
                
                return (
                    <RenderSuggestions 
                        key={type}
                        title={type} 
                        suggestionList={suggestionList}
                        searchProp={searchProp}
                        onSelect={handleSelectSearchProp}
                        setSuggestionIndex={setSuggestionIndex}
                        selectingSuggestion={selectingSuggestion}
                        games={type === "games"}
                        />
                )
            })}
            <div className="close-suggestion">
                <div onClick={() => setSuggestionListOpen(false)} className="close-suggestion-btn">
                    <PiArrowFatLinesUpBold/>
                    Close suggestions
                </div>
            </div>
        </div>
    )
}

export default SearchSuggestions