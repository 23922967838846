import React, {useState, useEffect, useRef} from "react"
import { useLoginSession } from "../../stores/loginSession";
import { useBackend } from "../../utility/Backend";
import { ClickOutside } from "../../utility/Utilities";
import { getDisplayDuration } from "../../utility/Utilities"
import {TiArrowSortedDown} from "react-icons/ti";
import { useCheckIsAdmin } from "../../utility/UserGroups";
import "./UploadPrePostRollSection.css"
import classNames from "classnames";

function UploadPrePostRollSection ({selected, setSelected, postRoll=false}) {

    const dropdownListRef = useRef(null) 
    const {token} = useLoginSession()
    const [,isAdmin] = useCheckIsAdmin()
    const searchRollInputRef = useRef(null)

    const [isListOpen, setIsListOpen] = useState(false)
    const [searchRoll, setSearchRoll] = useState("")
    const [rollIndex, setRollIndex] = useState(null)

    ClickOutside(dropdownListRef, setIsListOpen)

    useEffect(() => {
        if (isListOpen && searchRollInputRef) searchRollInputRef.current?.focus();
    }, [isListOpen])

    let query = {
        access_token: token,
        kind: "preroll",
        count: 30,
        from: 0
    }
    const fallbackMessage = "Not set"
    if (postRoll) query.kind = "postroll"

    const {data} = useBackend("/playlist/special", query)
    const listOfAlternatives = data?.playlists || []


    let rollsList = ["Not set"].concat(listOfAlternatives)
    if (searchRoll) {
        rollsList = rollsList
            .filter((roll) => roll !== "Not set")
            .filter((roll) => roll.description.toLowerCase().includes(searchRoll.toLowerCase()))
    }

    const onKeyPress = (e) => {
        // arrow down key
        if (e.keyCode === 40) {
            if (rollIndex === rollsList.length-1) return
            if (rollIndex === null) setRollIndex(0)
            else setRollIndex (rollIndex + 1)
        }
        // arrow up key
        if (e.keyCode === 38) {
            if (rollIndex === 0) return
            setRollIndex (rollIndex - 1)  
        }
        // enter key
        if (e.keyCode === 13) {
            onChange(rollsList[rollIndex]);
        }
        // escape key
        if (e.keyCode === 27) {
            setIsListOpen(false)
            setSearchRoll("")
            setRollIndex(null)
        }
    }

    useEffect(() => {
        if (isListOpen) window.addEventListener("keydown", onKeyPress);
        return () => {
            window.removeEventListener("keydown", onKeyPress);
        }
    })

    useEffect(() => {
        setRollIndex(null)
    }, [rollsList.length])

    const toggleListOpen = () => {
        setIsListOpen(!isListOpen)
        setTimeout(() => {
            if (dropdownListRef.current) dropdownListRef.current.scrollIntoView()
        }, 100)
    }

    const onChange = (playlist) => {
        if (playlist && playlist !== "Not set") setSelected([{id: playlist.id}])
        else setSelected([])
        setIsListOpen(false)
        setSearchRoll("")
        setRollIndex(null)
    }

    // Confusingly, selected is an array, but it is never more than 1 item long. This is because it's an array
    // in the API. This may need to change in the future, but in the meanwhile we have this weird code
    const selectedItem = listOfAlternatives.filter(v => v.id === selected[0]?.id)[0] || null

    // TODO If the selected item isnt in the list, e.g. for some reason you dont have access to this pre/postroll
    //      then this behaves weirdly. The item won't render, but it'll still be there...

    const renderRolls = rollsList.length > 0? (
        rollsList.map((playlist, idx) => {

            if (playlist === "Not set") return (
                <div 
                    key={"Not set"} 
                    onClick={() => onChange(null)} 
                    className={classNames("roll-not-set", {"active": !searchRoll && rollIndex === 0})}>
                    {fallbackMessage}
                </div>
            )

            const {id, description, thumbnail_url, duration_ms} = playlist
            
            return (
                <div 
                    key={id} 
                    onClick={() => onChange(playlist)} 
                    className={classNames("roll-single", {"active": rollIndex === idx})}>
                    <img src={thumbnail_url} alt="thumbnail"/>
                    <div className="chosen-roll-name">
                        <div>{description}</div>
                        <div>{getDisplayDuration(duration_ms)}</div>
                    </div>
                </div>
            )
        })
    ) : (
        <div className="no-roll-found">Nothing found</div>
    )

    return (
        <div ref={dropdownListRef} className="upload-dropdown-cont">
            {selectedItem ? (
                <div onClick={toggleListOpen} className="chosen-roll-cont">
                    <div className="chosen-roll-info">
                        <img src={selectedItem.thumbnail_url} alt="thumbnail"/>
                        <div className="chosen-roll-name">
                            <div>{selectedItem.description}</div>
                            <div>{getDisplayDuration(selectedItem.duration_ms)}</div>
                        </div>
                    </div>
                    <TiArrowSortedDown/>
                </div>
            ) : (
                <div onClick={toggleListOpen} className="upload-dropdown-title">
                    {fallbackMessage}
                    <TiArrowSortedDown/>
                </div>
            )}
            {isListOpen && (
                <div className="roll-list">
                    {isAdmin && (
                        <div className="upload-channel-search-cont">
                            <div className="upload-channel-search">Search : </div>
                            <input
                                ref={searchRollInputRef}
                                type="text"
                                onChange={(e) => setSearchRoll(e.target.value)}
                                value={searchRoll}
                                className=""/>
                        </div>
                    )}
                    {renderRolls}
                </div>
            )}
        </div>
    )
}

export default UploadPrePostRollSection