import React, {useState, useEffect, useRef} from "react"
import { getDateFormat } from "../../../utility/Utilities"
import { showErrorMessage } from "../../../utility/Utilities"
import classNames from "classnames"
import "../CreateStream/CreateStream.css"

function StreamDuration ({
    streamStartTime, 
    duration, 
    onChange, 
    createdStreamDuration=null, 
    hasInputError=null,
    setHasInputError=null,
    isLive=false,
}) {
    const customDurationInputRef = useRef(null)

    const [isCustomDuration, setIsCustomDuration] = useState(false)
    const [customDuration, setCustomDuration] = useState(createdStreamDuration || "")

    const streamStartHour = new Date(streamStartTime).getHours();
    const hourPlusDuration = streamStartHour + duration;
    const newStreamAfterDuration = new Date(streamStartTime).setHours(hourPlusDuration);
    const invalidCustomDuration = isCustomDuration && (!customDuration || customDuration === 0)
    const onGoingStream = new Date().getTime() - new Date(streamStartTime).getTime()
    const durationBelowRunningTime = onGoingStream > duration * 3600000
    const onGoingStreamHours = Math.floor(onGoingStream / 3600000)

    useEffect(() => {
        if (duration > 4) setIsCustomDuration(true)
    }, [duration])

    useEffect(() => {
        if (createdStreamDuration <= 4) setCustomDuration("")
    }, [createdStreamDuration])

    useEffect(() => {
        if (customDuration && durationBelowRunningTime) setHasInputError(true)
        if (customDuration && !durationBelowRunningTime) setHasInputError(false)
    }, [customDuration, durationBelowRunningTime])
    
    useEffect(() => {
        if (isCustomDuration && customDurationInputRef.current) customDurationInputRef.current?.focus()
    }, [isCustomDuration])

    const updateDuration = (duration) => {
        if (isCustomDuration) setIsCustomDuration(false)
        onChange(duration)
    }

    const selectCustomDuration = () => {
        setIsCustomDuration(true)
    }

    const updateCustomDuration = (duration) => {
        if (duration) {
            onChange(parseInt(duration))
            setCustomDuration(parseInt(duration))
        }
        else {
            onChange(duration)
            setCustomDuration(duration)
        }
    }

    return (
        <div className="stream-duration-cont">
            <div className="stream-duration-list-cont">
                <div className="stream-duration-list">
                    <button 
                        type="button"
                        disabled={onGoingStream > 3600000}
                        onClick={() => updateDuration(1)} 
                        className={classNames("stream-duration-single", {"active": duration === 1 && !isCustomDuration})}
                        >
                        1 hour
                    </button>
                    <button
                        type="button"
                        disabled={onGoingStream > 7200000}
                        onClick={() => updateDuration(2)} 
                        className={classNames("stream-duration-single", {"active": duration === 2 && !isCustomDuration})}
                        >
                        2 hours
                    </button>
                    <button 
                        type="button"
                        disabled={onGoingStream > 10800000}
                        onClick={() => updateDuration(3)} 
                        className={classNames("stream-duration-single", {"active": duration === 3 && !isCustomDuration})}
                        >
                        3 hours
                    </button>
                    <button 
                        type="button"
                        disabled={onGoingStream > 14400000}
                        onClick={() => updateDuration(4)} 
                        className={classNames("stream-duration-single", {"active": duration === 4 && !isCustomDuration})}
                        >
                        4 hours
                    </button>
                </div>
                <div onClick={selectCustomDuration} className={classNames("stream-custom-duration", {"active": isCustomDuration})}>
                    <div>Custom time</div>
                    {isCustomDuration && (
                        <>  
                            <div>:</div>
                            <input 
                                ref={customDurationInputRef} 
                                type="number" 
                                value={customDuration} 
                                onChange={(e) => updateCustomDuration(e.target.value)}/>
                            <div>hours</div>
                        </>
                    )}
                </div>
            </div>
            <div className="stream-time-info margin-bottom">
                Ingest available until {getDateFormat(newStreamAfterDuration, true, true)}
            </div>
            {hasInputError && showErrorMessage("Stream must have duration", invalidCustomDuration)}
            {showErrorMessage("Maximum duration 9 hours", customDuration > 9)}
            {showErrorMessage(`Stream has been running for more than ${onGoingStreamHours} hour(s)`, (isLive && customDuration && durationBelowRunningTime))}
        </div>
    )
}

export default StreamDuration