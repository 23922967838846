import "./EditingControls.css";
import "../../../../components/css/ListingFilter.css";
import {useVideoPlayer} from "../../../../components/VideoPlayer/VideoPlayerProvider";
import {useLoginSession} from "../../../../stores/loginSession";
import React, {useReducer, useMemo} from "react";
import Backend from "../../../../utility/Backend";
import EditingTimeline from "./EditingTimeline";
import NewSubclipForm from "./NewSubclipForm";
import { useFeedbackMessage } from "../../../../stores/FeedbackMessage";
import { useMutateByRegex } from "../../../../utility/Utilities";
import { useLocation } from "react-router-dom";

function initNewClipReducer ({getCurrentTime, duration, hockeyMasterAsset}) {

    const current = Math.round(getCurrentTime() / 2) * 2;
    let subClipPadding = 15
    let startPadding = subClipPadding
    let endPadding = subClipPadding

    if (current < subClipPadding) {
        startPadding = current
        endPadding = (subClipPadding*2) - current
    } 
    if ((duration - current) < subClipPadding) {
        startPadding = (subClipPadding*2) - (duration - current)
        endPadding = duration - current
    }

    const start = Math.max(0, current - startPadding);
    const end = Math.min(duration, current + endPadding);

    // 1.5 mins both ends in hockey and 1 min in sef
    const clipStartRange = hockeyMasterAsset? 90 : 60
    const timelineStart = Math.max(0, current - clipStartRange)
    const clipEndRange = Math.max(90, 180 - current)
    const timelineEnd = Math.min(duration, current + clipEndRange)

    return {
        timelineStart,
        timelineEnd,
        start: start - timelineStart,
        end: end - timelineStart,
    }
}

const defaultFormReducer = {
    description: "",
    is_private: true,
    channels: [],
    prerolls: [],
    postrolls: [],
    thumbnail_url: null,
}

function formReducer (state, action) {
    switch (action.type) {
        case "reset": return defaultFormReducer
        default: {
            if (state.hasOwnProperty(action.type))
                return {...state, [action.type]: action.payload}
            return state
        }
    }
}

export default function CreateNewSubclip ({playlist, hockeyMasterAsset=false, onDone}) {
    
    let {
        playbackUrl,
        duration,
        seekTo,
        getCurrentTime,
    } = useVideoPlayer();

    const {showFeedback} = useFeedbackMessage();
    const mutateByRegex = useMutateByRegex()

    if (duration === 0) duration = 0.0001; // Super edge case

    function newClipReducer (state, action) {
        let {timelineStart, timelineEnd, start, end} = state;

        switch (action.type) {
            case "setStart": {
                let playbackPos = action.position;
                playbackPos = Math.max(0, Math.min(duration - 2, playbackPos));
                if (playbackPos < timelineStart) {
                    const oldOffset = timelineStart;
                    timelineStart = Math.max(0, playbackPos - 60);
                    end += oldOffset - timelineStart;
                } else if (playbackPos > timelineEnd) {
                    timelineStart = Math.max(0, playbackPos - 60);
                    timelineEnd = Math.min(duration, playbackPos + 60);
                }
                start = playbackPos - timelineStart;
                if (start >= end) {
                    end = Math.min(duration, (playbackPos + 2)) - timelineStart;
                }
                return {timelineStart, timelineEnd, start, end};
            }
            case "setEnd": {
                let playbackPos = action.position;
                playbackPos = Math.max(2, Math.min(duration, playbackPos));
                if (playbackPos < timelineStart) {
                    timelineStart = Math.max(0, playbackPos - 60);
                    timelineEnd = Math.min(duration, playbackPos + 60);
                } else if (playbackPos > timelineEnd) {
                    timelineEnd = Math.min(duration, playbackPos + 60);
                }
                end = playbackPos - timelineStart;
                if (end <= start) {
                    start = Math.max(0, end - 2);
                }
                return {timelineStart, timelineEnd, start, end};
            }
            case "zoomIn": {
                const diff = timelineStart - Math.max(0, timelineStart + start - 30);
                timelineStart -= diff;
                start += diff;
                end += diff;
                timelineEnd = Math.min(duration, timelineStart + end + 30);
                return {timelineStart, timelineEnd, start, end};
            }
            case "zoomOut": {
                const timelineDuration = timelineEnd - timelineStart;
                const padding = Math.round(timelineDuration / 4) * 2;
                const diff = timelineStart - Math.max(0, timelineStart - padding);
                timelineStart -= diff;
                start += diff;
                end += diff;
                timelineEnd = Math.min(duration, timelineEnd + padding);
                return {timelineStart, timelineEnd, start, end};
            }
            default: return state;
        }
    }

    const {token} = useLoginSession();
    const [state, dispatch] = useReducer(newClipReducer, {getCurrentTime, duration, hockeyMasterAsset}, initNewClipReducer);
    const [formState, formDispatch] = useReducer(formReducer, defaultFormReducer);
    const {start, end, timelineStart, timelineEnd} = state;

    const timeStart = timelineStart + start
    const timeEnd = timelineStart + end
    const splitUrl = playbackUrl.split("/");
    const [assetId, offsetMs] = splitUrl[splitUrl.length - 2].split(":").map(e => parseInt(e));
    const fromTimestamp = playlist?.is_live ? (timeStart * 1000) : offsetMs + (timeStart * 1000)
    const toTimestamp = playlist?.is_live ? (timeEnd * 1000) : offsetMs + (timeEnd * 1000)

    const videoAssetName = playlist?.events[0].video_asset_name

    const playlistClips = useMemo(() => [{
        from: fromTimestamp,
        to: toTimestamp,
        assetName: videoAssetName,
    }], [fromTimestamp, toTimestamp, videoAssetName])
    
    const editingQuery = new URLSearchParams()
    let {pathname} = useLocation();
    if (!pathname.startsWith("/library/videos?")) {
        pathname = "/library/videos?";
    }

    function dispatchFormData (type, payload) {
        formDispatch({type: type, payload: payload})
    } 

    function onDiscard () {
        // TODO Probably need to do more checks here, maybe a verification
        onDone();
    }

    async function onSave (tags=[], thumbnailImage=null) {

        const eventsParams = [{
            video_asset_id: assetId,
            description: formState.description,
            tags: tags,
            from_timestamp: fromTimestamp,
            to_timestamp: toTimestamp,
        }]
        // place events array to the subclip form state
        formState.events = eventsParams

        // POST request for uploading thumbnail
        if (thumbnailImage) {
            let body = new FormData();
            body.append("image", thumbnailImage);

            const {data: uploadedImageData, error: uploadImageError} = await Backend.post(
                "/userimage",
                body,
                {type: "thumbnail", access_token: token},
                {json:false}
            )
            if (uploadImageError) {
                showFeedback("warning", "Failed to upload image: " + uploadImageError)
                return
            }
            const uploadedThumbnailUrl = uploadedImageData.path
            formDispatch({type: "thumbnail_url", payload: uploadedThumbnailUrl})
        }

        const query = {access_token: token};

        console.log(JSON.stringify(formState, undefined, 2));
        
        Backend.post("/playlist/", JSON.stringify(formState), query)
            .then(({error, response}) => {
                if (error) {
                    console.error("Failed to POST", error);
                    showFeedback("warning", "Failed to create" + error);
                } else {
                    const location = response.headers.get("Location");
                    const playlistId = /[^/]*$/.exec(location)[0];
                    editingQuery.set("editing", "playlist_" + playlistId);
                    const link = pathname + editingQuery;
                    
                    mutateByRegex(/^\/playlist\//)
                    onDone();
                    showFeedback("success", "New subclip created successfully", link);
                }
            });
    }

    return (
        <>
            <EditingTimeline
                timelineStart={timelineStart}
                timelineEnd={timelineEnd}
                start={start}
                end={end}
                setStart={(s) => dispatch({type: "setStart", position: s})}
                setEnd={(s) => dispatch({type: "setEnd", position: s})}
                getCurrentPlaybackTime={getCurrentTime}
                seekTo={seekTo}
                hockeyMasterAsset={hockeyMasterAsset}
                zoomIn={() => dispatch({type: "zoomIn"})}
                zoomOut={() => dispatch({type: "zoomOut"})}
                type="subclip"
            />
            <NewSubclipForm
                formState={formState}
                dispatchFormData={dispatchFormData}
                onSave={onSave}
                onDiscard={onDiscard}
                playlistClips={playlistClips}
                />
        </>
    );
}