import React, {useState} from "react"
import CustomModal from "../../components/CustomModal"
import UploadImage from "../../components/UploadImage"
import classNames from "classnames"
import "./UploadThumbnailSection.css"
import {FaTimes} from "react-icons/fa";
import {FiCheckSquare, FiSquare} from "react-icons/fi";
import {BsInfoCircle} from "react-icons/bs";
import {IoIosRedo} from "react-icons/io";
import {CgMaximize} from "react-icons/cg";

function UploadThumbnailSection ({
    uploadThumbnail, 
    setUploadThumbnail, 
    imageUrl, 
    selectThumbnail,
    customThumbnail,
    setCustomThumbnail,
}) {

    const [previewThumbnail, setPreviewThumbnail] = useState(false)

    const handleCustomThumbnail = () => {
        if (!imageUrl) setUploadThumbnail(true)
        setCustomThumbnail(true)
    }

    const cancelUploadThumbnail = (url=null) => {
        if (!url && !imageUrl) setCustomThumbnail(false)
        setUploadThumbnail(false)
    }
    
    const previewThumbnailModal = (
        <CustomModal isOpen onRequestClose={() => setPreviewThumbnail(false)} className="preview-modal">
            <div className="preview-thumbnail">
                <div onClick={() => setPreviewThumbnail(false)} className="close-preview-thumbnail">
                    <FaTimes/>
                    Close
                </div>
                <img src={imageUrl} alt="thumbnail"/>
            </div>
        </CustomModal>
    )

    function enablePreviewThumbnail (e) {
        e.preventDefault()
        e.stopPropagation()
        setPreviewThumbnail(true)
    }

    const thumbnailOptions = (
        <div className="thumbnails-options">
            <div
                onClick={() => setCustomThumbnail(false)} 
                className={classNames("option-checkbox", {"active": !customThumbnail})}>
                {!customThumbnail? 
                    <FiCheckSquare className="check-box-icon"/> : 
                    <FiSquare className="check-box-icon"/>}
                <div className="option-box-title">
                    Auto-generated thumbnail
                    <div className="option-extra-message"><BsInfoCircle className="info-icon"/> thumbnail taken from the video</div>
                </div>
            </div>
            <div
                onClick={handleCustomThumbnail}  
                className={classNames("option-checkbox", {"active": customThumbnail})}>
                <div className="customize-thumbnails-cont">
                    <div className="customized-thumbnails-title">
                        {customThumbnail? 
                            <FiCheckSquare className="check-box-icon"/> : 
                            <FiSquare className="check-box-icon"/>}
                        <div className="option-box-title">
                            Customized thumbnail
                            <div className="option-extra-message"><BsInfoCircle className="info-icon"/> customize by uploading an image</div>
                        </div>
                    </div>
                    {(customThumbnail && imageUrl) && (
                        <div className="upload-thumbnail-img">
                            <img src={imageUrl} alt="thumbnail"/>
                            <div className="thumbnail-overlay-icon" onClick={() => setUploadThumbnail(true)} >
                                <IoIosRedo onClick={() => setUploadThumbnail(true)} className="thumbnail-redo-max-icon"/>
                                <CgMaximize onClick={enablePreviewThumbnail} className="thumbnail-redo-max-icon"/>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {previewThumbnail && previewThumbnailModal}
        </div> 
    )

    return (
        <>
            {thumbnailOptions}
            {uploadThumbnail && (
                <UploadImage 
                    onClose={cancelUploadThumbnail}
                    onChange={selectThumbnail}/>
            )}
        </>
    )
}

export default UploadThumbnailSection