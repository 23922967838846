import React, {useState, useRef} from "react"
import Config from "../../utility/Config";
import { useBackend } from "../../utility/Backend";
import { ClickOutside } from "../../utility/Utilities";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./UploadMatchAssociate.css"
import "./Uploads.css"
import classNames from "classnames";
import {TiArrowSortedDown} from "react-icons/ti";
import {ImCalendar} from "react-icons/im";
import {FaTimes} from "react-icons/fa";

function UploadMatchAssociate ({selectedGame, setSelectedGame}) {

    const dropdownListRef = useRef(null)
    const seasonListRef = useRef(null)
    const teamListRef = useRef(null)
    
    const [selectedSeason, setSelectedSeason] = useState(Config.seasons[0])
    const [selectedTeam, setSelectedTeam] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null)

    const [isMatchListOpen, setIsMatchListOpen] = useState(false)
    const [isSeasonListOpen, setIsSeasonListOpen] = useState(false)
    const [isTeamListOpen, setIsTeamListOpen] = useState(false)

    ClickOutside(dropdownListRef, setIsMatchListOpen)
    ClickOutside(seasonListRef, setIsSeasonListOpen)
    ClickOutside(teamListRef, setIsTeamListOpen)

    let fromDate, toDate
    if (selectedDate) {
        const updatedDate = new Date(selectedDate.setHours(new Date().getHours() + 2))
        fromDate = updatedDate.toISOString().split("T")[0]
        toDate = updatedDate.toISOString().split("T")[0]
    } else {
        let [start, end] = Config.seasonInterval(selectedSeason)
        fromDate = start.toISOString().split("T")[0]
        if (selectedSeason === Config.seasons[0]) {
            const todayPlusTen = new Date(new Date().setDate(new Date().getDate() + 10))
            toDate = todayPlusTen.toISOString().split("T")[0]
        } else {
            toDate = end.toISOString().split("T")[0]
        }
    }

    const matchQuery = {
        count: 250,
        team_id: selectedTeam?.id || undefined,
        from_date: fromDate,
        to_date: toDate,
    }

    const {data: matches} = useBackend("/game", matchQuery)
    const {data: activeTeams} = useBackend("/team", {season: selectedSeason})

    const games = matches?.games || []
    const teams = activeTeams?.teams || []

    const handleMatchList = () => setIsMatchListOpen(!isMatchListOpen)
    const handleSeasonList = () => setIsSeasonListOpen(!isSeasonListOpen)
    const handleTeamList = () => setIsTeamListOpen(!isTeamListOpen)
    
    const handleSelectSeason = (season) => {
        setSelectedSeason(season)
        handleSeasonList()
    }

    const handleSelectTeam = (team) => {
        setSelectedTeam(team)
        handleTeamList()
    }

    const handleSelectGame = (game) => {
        setSelectedGame(game)
        setIsMatchListOpen(false)
    }

    const matchAssociate = (
        <div  className="match-associate-cont">
            <div ref={dropdownListRef} className="upload-dropdown-cont">
                {selectedGame? (
                    <div className="upload-selected-match-cont" onClick={handleMatchList}>
                        <div className="upload-selected-match">
                            <div className="upload-selected-match-date">
                                {selectedGame.date}
                            </div>
                            <div>{selectedGame.home_team.name}</div>
                            <img src={selectedGame.home_team.logo_url} alt="logo"/>
                            {selectedGame.home_team_goals}
                            <div>-</div>
                            {selectedGame.visiting_team_goals}
                            <img src={selectedGame.visiting_team.logo_url} alt="logo"/>
                            {selectedGame.visiting_team.name}
                        </div>
                        <TiArrowSortedDown/>
                    </div>
                ) : (
                    <div onClick={handleMatchList} className="upload-dropdown-title">
                        No match
                        <TiArrowSortedDown/>
                    </div>
                )}
                {isMatchListOpen && (
                    <div className="match-associate-list-cont">
                        <div className="match-associate-filter-cont">
                            <div ref={teamListRef} className="match-associate-filter">
                                <div onClick={handleTeamList} className="match-associate-filter-title">
                                    {selectedTeam?.name || "All teams"}
                                    <TiArrowSortedDown/>
                                </div>
                                {isTeamListOpen && (
                                    <div className="match-associate-filter-list">
                                        <div 
                                            onClick={() => handleSelectTeam(undefined)}
                                            className="match-associate-filter-single">
                                            All teams
                                        </div>
                                        {teams.map((team) => {
                                            const {id, name} = team
                                            return (
                                                <div 
                                                    key={id}
                                                    onClick={() => handleSelectTeam(team)}
                                                    className="match-associate-filter-single">
                                                    {name}
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>
                            {!selectedDate && (
                                <div ref={seasonListRef} className="match-associate-filter">
                                    <div onClick={handleSeasonList} className="match-associate-filter-title">
                                        {selectedSeason}
                                        <TiArrowSortedDown/>
                                    </div>
                                    {isSeasonListOpen && (
                                        <div className="match-associate-filter-list">
                                            {Config.seasons.map((season) => {
                                                return (
                                                    <div
                                                        key={season}
                                                        onClick={() => handleSelectSeason(season)}
                                                        className="match-associate-filter-single">
                                                        {season}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )}
                                </div>
                            )}
                            <label className={classNames("match-date-picker", {"has-date": selectedDate})}>
                                <ImCalendar className="match-date-icon"/>
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={(date) => setSelectedDate(date)}
                                    showYearDropdown
                                    scrollableYearDropdown
                                    dateFormat="yyyy-MM-dd"
                                    />
                                {selectedDate && <FaTimes onClick={() => setSelectedDate(null)} className="remove-date-icon"/>}
                            </label>
                        </div>
                        <div className="match-associate-games-list">
                            <div
                                onClick={() => handleSelectGame(null)} 
                                className="match-associate-games-single no-match">No match</div>
                            {games.map((game) => {
                                const {id, home_team, home_team_goals, visiting_team, visiting_team_goals, date} = game
                                return (
                                    <div 
                                        key={id}
                                        onClick={() => handleSelectGame(game)}
                                        className="match-associate-games-single"
                                        >
                                        <div className="match-associate-date">{date}</div>
                                        <div className="match-associate-single-info">
                                            <div className="match-associate-team home">{home_team.name}</div>
                                            <img src={home_team.logo_url} alt="logo"/>
                                            {home_team_goals}
                                            <div>-</div>
                                            {visiting_team_goals}
                                            <img src={visiting_team.logo_url} alt="logo"/>
                                            <div className="match-associate-team">{visiting_team.name}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )

    return (
        <>{matchAssociate}</>
    )
}

export default UploadMatchAssociate