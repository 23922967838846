import React, {useState, useRef} from "react";
import Config from "../utility/Config";
import "./css/Dropdown.css";
import "./css/ListingFilter.css";
import { IoMdArrowDropdown } from "react-icons/io"
import classNames from "classnames";
import { ClickOutside } from "../utility/Utilities";

export default function Dropdown ({
    options, // required, array of strings
    fallback, // required, default value
    onChange, // required
    render = null, // render function taking id as param. E.g. (id) => options[id].name
    btnRender = null, // render function for the button. Falls back to render
    selected = null,
    disabled = false,
    title = null,
    special = null, // an option not in the normal list of options, will be listed first. Usually "all"
}) {
    const [open, setOpen] = useState(false);

    const dropdownRef = useRef(null)
    ClickOutside(dropdownRef, setOpen)

    const isSef = Config.association === "SEF"
    selected = selected ? selected : fallback;

    function displayName (id) {
        
        if (id === "goalkeeperevent") return "goalkeeper event"
        if (id === "shootoutpenaltyshot") return "shoot out penalty"

        if (id === special || !render) return id;
        return render(id);
    }

    function displayBtnName (id) {
        try {
            if (!btnRender) return displayName(id);
            return (id === special) ? id : btnRender(id);
        } catch (e) {
            //This happens typically if the selected option isnt in the list of options
            console.error(e);
            return id;
        }
    }

    if (disabled) {
        return (
            <div className="filter-dropdown disabled">
                {title && (
                    <div className="filter-title">{title}</div>
                )}
                <div className="filter-selected">
                    {displayBtnName(selected)}
                </div>
            </div>
        );
    }

    function renderItem (id, idx) {
        function onClick () {
            onChange(id === fallback ? null : id);
            setOpen(false);
            return false;
        }

        return (
            <li key={id}
                tabIndex={idx+1}
                className={"dropdown-item" + (id === selected ? " active" : "")}
                onClick={onClick}>
                {displayName(id)}
            </li>
        );
    }

    return (
        <div
            ref={dropdownRef}
            onClick={() => setOpen(!open)} 
            className={classNames("filter-dropdown", {
                "open": open,
                "narrow": title === "Season" || title === "Round" || title === "Types",
                "wide": title === "Channel" || title === "Player"
            })}>
            <div className="filter-title">{title}</div>
            <div className="filter-selected">
                {displayBtnName(selected) || "-"}
                <span className="dropdown-arrow"><IoMdArrowDropdown /></span>
            </div>
            <ul className="filter-dropdown-list">
                {special && renderItem(special, -1)}
                {options.map(renderItem)}
            </ul>
        </div>
    );
}

