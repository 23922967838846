import React, { useEffect, useState } from "react";
import CustomModal from "./CustomModal";
import { useFeedbackMessage } from "../stores/FeedbackMessage";
import { FaImage } from "react-icons/fa";
import "./css/ThumbnailSelector.css";

function UploadImage ({ 
    onChange,
    onClose,
}) {

    const {showFeedback} = useFeedbackMessage();

    const [isDropping, setIsDropping] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [errorMsg, setErrorMsg] = useState("");

    const showErrorMessage = (message="") => {
        setErrorMsg(message);
    }

    useEffect(() => {
        if (imageFile && imageUrl) showErrorMessage();
    }, [imageFile, imageUrl]);

    const onAreaOver = (e) => e.preventDefault();
    const onAreaEnter = () => setIsDropping(true);
    const onAreaLeave = () => setIsDropping(false);
    
    let fileTargetUrl = null;
    const onSelectFile = (e) => {
        if (!e.target.files[0]){
            return null;
        }

        if (e.target.files[0].size > 1000000){
            showErrorMessage("Maximum size 1 MB");
            return null;
        }

        const fileExt = e.target.files[0].name.split(".").pop()
        const validFileTypes = ["jpg", "JPG", "jpeg", "JPEG", "png", "PNG"]
        
        if (!validFileTypes.includes(fileExt)) {
            showErrorMessage("Unsupported image file extension ." + fileExt + ". Must be either a JPG or PNG")
            return null;
        }

        setImageFile(e.target.files[0]);
        fileTargetUrl = URL.createObjectURL(e.target.files[0]);
        setImageUrl(fileTargetUrl);
    }

    const onFileDrop = (e) => {
        e.preventDefault();

        if (e.dataTransfer.files.length > 1){
            showErrorMessage("Can only upload one image");
            setIsDropping(false);
            return;
        }

        if (e.dataTransfer.files[0].size > 1000000){
            showErrorMessage("Maximum size 1 MB");
            setIsDropping(false);
            return;
        }

        const fileExt = e.dataTransfer.files[0].name.split(".").pop();
        const validFileTypes = ["jpg", "JPG", "jpeg", "JPEG", "png", "PNG"];
        
        if (!validFileTypes.includes(fileExt)) {
            showErrorMessage("Unsupported image file extension ." + fileExt + ". Must be either a JPG or PNG");
            setIsDropping(false);
            return;
        }
        setImageFile(e.dataTransfer.files[0])
        setImageUrl(URL.createObjectURL(e.dataTransfer.files[0]));
        setIsDropping(false);
        showFeedback();
    };

    const revokeObjectURL = () => {
        URL.revokeObjectURL(fileTargetUrl);
    }

    const onClickCancel = () => {
        onClose()
        setImageFile(null);
        setImageUrl(null);
        showFeedback();
        showErrorMessage();
        revokeObjectURL();
    }

    const onClickSubmit = () => {
        onChange({imageFile, imageUrl})
        onClose(imageUrl)
        setImageFile(null);
        setImageUrl(null);
    }

    const noImageChosen = !imageFile && !imageUrl;

    const uploadThumbnailCont = (
        <div className="upload-thumbnail-cont">
            <div
            onDragOver={onAreaOver}
            onDrop={onFileDrop}
            onDragEnter={onAreaEnter}
            onDragLeave={onAreaLeave}
            className={`upload-thumbnail-dnd ${isDropping? " is-dropping" : ""}`}
            >   
                {imageUrl? (
                    <img src={imageUrl} alt="thumbnail" className="uploaded-thumbnail-img"/>
                ) : (
                    <FaImage className="image-upload-icon"/>
                )}
                <p>{imageFile? "Drag file here to change file" : 
                        "Drag file here to upload" }</p>
                <p>or</p>
                <input 
                    type="file" 
                    name="file" 
                    id="file"
                    className="image-upload-input"
                    onChange={onSelectFile}
                    accept="image/jpeg,image/*"/> 
                <label htmlFor="file" 
                    className="image-input-label">
                    Choose Image
                </label>
                <div className="max-image-size-info">Maximum size 1 MB</div>
            </div>
            <div className="warning-msg">{errorMsg}</div>
            <div className="confirm-cancel-btn-cont full">
                <button 
                    type="button" 
                    disabled={noImageChosen}
                    onClick={onClickSubmit}
                    className="green-hover-btn">
                    Confirm
                </button>
                <button 
                    type="button" 
                    onClick={onClickCancel}>
                    Cancel
                </button>
            </div>
        </div>
    );

    return (
        <CustomModal isOpen onRequestClose={onClickCancel} className="upload-image">
            {uploadThumbnailCont}
        </CustomModal>
    )
}

export default UploadImage;