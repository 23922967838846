import React from "react"
import { RenderEvent, RenderPhaseEvent } from "./MatchTimelineEvents";
import Config from "../../../utility/Config"
import { useVideoPlayer } from "../../../components/VideoPlayer/VideoPlayerProvider";
import { hockeyTimeFormat } from "../../../utility/Utilities"

export const phases = ["start phase", "end phase", "end of game"]

function calculateGameTime (event) {
    const matchPhase = phases.includes(event.tag.action)
    if (matchPhase) return event.tag.action
    if (Config.association === "SHL") return hockeyTimeFormat(event.tag, true)
    return Math.ceil(event.game_time / 60)
}

function MatchTimelineList ({playlist, filteredEvents, timelineMouseDown}) {

    const {videoProgress} = useVideoPlayer();

    // need to check the start timestamp, because it is not always start at zero (f.ex football)
    const gameStartTimeStamp = playlist.events[0].from_timestamp
    const gameEndTimeStamp = playlist.events[0].to_timestamp

    let offset = 0
    let lastMinute = null
    let side = null

    let homePenaltyShootoutIndex = -1
    let awayPenaltyShootoutIndex = -1

    const handleOffset = (sameTimeAndSameSide=false, isPenaltyShootout=false) => {

        // need to handle penalty shootout because penalty has same game time, and 
        // both home and away teams take turn every other time
        if (isPenaltyShootout) {
            if (side === "home") {
                homePenaltyShootoutIndex += 1
                return offset = homePenaltyShootoutIndex
            }
            else {
                awayPenaltyShootoutIndex += 1
                return offset = awayPenaltyShootoutIndex 
            } 
        }
        if (!isPenaltyShootout && sameTimeAndSameSide) return offset += 1
        else return 0
    }

    const playlistsForTimeline = filteredEvents.map((e, idx) => {

        const gameTime = calculateGameTime(e)
        const isMatchPhase = phases.includes(gameTime)

        const teamId = !isMatchPhase && e.tag?.team?.id
        const teamEvent = teamId === e.playlist?.game?.home_team.id? "home" : "away"
        // offset plus 1 if an event has the same minute and the same team side (home/away)
        const sameTimeAndSameSide = gameTime === lastMinute && teamEvent === side
        // special handling for penalty shootout
        const penaltyShootout = e.tag.action === "shootoutpenaltyshot"

        lastMinute = gameTime
        side = teamEvent
        offset = handleOffset(sameTimeAndSameSide, penaltyShootout)
        
        if (isMatchPhase) return (
            <RenderPhaseEvent 
                key={e.id+idx} 
                event={e} 
                startTime={gameStartTimeStamp} 
                endTime={gameEndTimeStamp}/>
        )

        return (
            <RenderEvent 
                key={e.id+idx} 
                event={e} 
                startTime={gameStartTimeStamp} 
                endTime={gameEndTimeStamp} 
                index={offset}
                side={teamEvent}
                />
        )
    })
    
    const homeTeamLogo = playlist.game.home_team.logo_url
    const awayTeamLogo = playlist.game.visiting_team.logo_url

    return (
        <>  
            <div className="timeline-separator" onMouseDown={timelineMouseDown}>
                <div className="timeline-progress" style={{width: `${videoProgress}%`}}></div>
                <div className="timeline-progress-dot" style={{left: `${videoProgress}%`}}></div>
            </div>
            <div className="match-timeline">
                {playlistsForTimeline}
            </div>
            <div className="timeline-team-logo-cont">
                <img src={homeTeamLogo} alt="logo" />
                <img src={awayTeamLogo} alt="logo" />
            </div>
        </>
    )
}

export default MatchTimelineList