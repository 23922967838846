import React, {useState, useEffect} from "react";
import { useBackend, generateHref } from "../../utility/Backend";
import { useLoginSession } from "../../stores/loginSession";
import { useMutateByRegex } from "../../utility/Utilities";
import StreamDetails from "./StreamDetails/StreamDetails";
import { Paging } from "../../utility/Paging";
import { LoadingPage } from "../../components/Page";
import Page, {DualPage} from "../../components/Page";
import CreateStream from "./CreateStream/CreateStream";
import {Helmet} from "react-helmet-async";
import {useUpdateSearchParams} from "../../utility/Utilities";
import classNames from "classnames";
import StreamList from "./StreamList/StreamList";
import {useMediaQuery} from "react-responsive";
import "./Streams.css";
import "../../DataInfoUtility.css"
import { BiVideoRecording } from "react-icons/bi";
import {BiRefresh} from "react-icons/bi";
import {FaCheck} from "react-icons/fa";
import RenderStreamsMobile from "./StreamList/RenderStreamsMobile";

function RenderStreams ({stoppingStream, setStoppingStream}) {

    const [searchParams, updateSearchParams, resetAllSearchParamsExcept] = useUpdateSearchParams();
    const {token} = useLoginSession();

    const streamTypeParam = searchParams.get("type") || "scheduled"
    const streamPageParam = searchParams.get("page")
    const leagueParams = searchParams.get("league") || undefined
    
    const [streamsPerPage, setStreamsPerPage] = useState(4)

    const page = streamPageParam ? parseInt(streamPageParam) : 1
    const from = streamPageParam ? (page-1) * streamsPerPage : 0

    const eightStreamsPerPage = useMediaQuery({minHeight: 960})
    
    useEffect(() => {
        if (eightStreamsPerPage) setStreamsPerPage(8)
        else setStreamsPerPage(4)
    }, [eightStreamsPerPage])

    const tenDaysAgo = new Date()
    tenDaysAgo.setDate(tenDaysAgo.getDate() - 10)
    tenDaysAgo.setMinutes(0, 0, 0)

    const fetchStreamProps = {
        access_token: token,
        asc: true,
        from: from,
        count: streamsPerPage,
        all_leagues: leagueParams ? undefined : true,
        status: streamTypeParam,
    }
    
    if (streamTypeParam === "scheduled") fetchStreamProps.from_date = tenDaysAgo.toISOString()
    if (streamTypeParam === "finished") fetchStreamProps.asc = false

    const liveIngestApiPath = generateHref("/live_ingest/editable", leagueParams)
    const {data: streamsData} = useBackend(liveIngestApiPath, fetchStreamProps, {
        refreshInterval: 10000,
    });

    const streamListData = streamsData?.live_ingests || []
    const totalStreams = streamsData?.total
    const totalPage = Math.ceil(totalStreams / streamsPerPage)

    useEffect(() => {
        // TODO is it really only possible to be stopping one stream at the time? This seems weird...
        if (!stoppingStream || !streamsData) return
        if (!streamListData.find(s => s.id === stoppingStream)) setStoppingStream(null)
    }, [stoppingStream, streamsData])

    if (!streamsData) return <LoadingPage/>

    const onChangePage = (page) => {
        if (page > 1) updateSearchParams({"page": page})
        else resetAllSearchParamsExcept(["type", "stream"])
    }

    if (streamListData.length === 0) return (
        <div className="no-streams">No {streamTypeParam} streams</div>
    )

    return (
        <>
            <div className={classNames("stream-list-container", {"tall": eightStreamsPerPage})}>
                <StreamList streams={streamListData} stoppingStream={stoppingStream}/>
            </div>
            <Paging page={page} pageCount={totalPage} onChange={onChangePage}/>
        </>
    )
}

export default function Streams () {

    const [searchParams, updateSearchParams, resetAllSearchParamsExcept] = useUpdateSearchParams();

    const mobileDevice = useMediaQuery({maxWidth: 768})
    const {token} = useLoginSession();
    const leagueParams = searchParams.get("league") || undefined
    const liveIngestApiPath = generateHref("/live_ingest/editable", leagueParams)
    
    const {data: liveStreamsData} = useBackend(liveIngestApiPath, {
        access_token: token,
        asc: true,
        count: 50,
        all_leagues: leagueParams ? undefined : true,
        status: "live",
    }, {
        refreshInterval: 10000,
    });

    const streamTypeParam = searchParams.get("type") || "scheduled"
    const streamPageParam = searchParams.get("page")
    const streamKeyParam = searchParams.get("stream")

    const showStreamDetails = streamKeyParam && streamKeyParam !== "schedule"
    const isScheduleNewStream = streamKeyParam === "schedule"

    const [stoppingStream, setStoppingStream] = useState(null)
    const [streamRefreshed, setStreamRefreshed] = useState(false)

    useEffect(() => {
        if (streamPageParam === "1") resetAllSearchParamsExcept(["stream", "type"])
    }, [streamPageParam])

    useEffect(() => {
        const clearRefreshStream = setInterval(()=> {
            if (streamRefreshed) setStreamRefreshed(false)
        }, 2000)
        return () => clearInterval(clearRefreshStream);
    }, [streamRefreshed])

    const mutateByRegex = useMutateByRegex();
    const refreshStreams = () => {
        mutateByRegex(/^\/|(live_ingest)/)
        setStreamRefreshed(true)
    }

    const scheduleStream = () => {
        updateSearchParams({"stream": "schedule"})
    }

    const onUpdateStreamType = (type=null) => {
        if (!type) {
            updateSearchParams("type")
        } else {
            updateSearchParams({"type": type})
            resetAllSearchParamsExcept(["type", "stream"])
        }
    }

    const fullHeight = useMediaQuery({minHeight: 1081})

    const streamFilter = (
        <div className={classNames("stream-filter-cont", {"extra-margin": fullHeight})}>
            <div 
                onClick={() => onUpdateStreamType("live")} 
                className={classNames("stream-filter-single", {"active": streamTypeParam === "live"})}>
                Live streams
                {liveStreamsData?.total > 0 && ` (${liveStreamsData.total})`}
            </div>
            <div 
                onClick={() => onUpdateStreamType()} 
                className={classNames("stream-filter-single", {"active": streamTypeParam === "scheduled"})}>
                Upcoming streams
            </div>
            <div 
                onClick={() => onUpdateStreamType("finished")} 
                className={classNames("stream-filter-single", {"active": streamTypeParam === "finished"})}>
                Finished streams
            </div>
        </div>
    )

    const emptyEditPage = (
        <div className="flex-vertically-centered is-loading">
            <div>
                <BiVideoRecording className="no-stream-selected-icon"/>
                <br />
                None selected
            </div>
        </div>
    )

    const refreshStream = (
        <div onClick={refreshStreams} className="refresh-stream">
            <div className="refresh-stream-icon">
                {streamRefreshed ? <FaCheck className="stream-refreshed-check"/> : <BiRefresh className="refresh-icon"/>}
            </div>
            Refresh
        </div>
    )

    return (
        <DualPage withLayoutSelection defaultLayout={0} rightPageQueryParam="stream">
            <Page centered title="Streams">
                <Helmet>
                    <title>Streams</title>
                </Helmet>
                <div className="schedule-new-stream">
                    <button type="button" onClick={scheduleStream} className="green-hover-btn">
                        Schedule new stream
                    </button>
                </div>
                <div className="stream-page">
                    {streamFilter}
                    {refreshStream}
                    {mobileDevice ? (
                        <RenderStreamsMobile/>
                    ) : (
                        <RenderStreams stoppingStream={stoppingStream} setStoppingStream={setStoppingStream}/>
                    )}
                </div>
            </Page>
            <Page title={isScheduleNewStream? "schedule stream" : "stream details"}>
                {showStreamDetails && (
                    <StreamDetails 
                        stoppingStream={stoppingStream}
                        setStoppingStream={setStoppingStream}/>
                )}
                {isScheduleNewStream && (
                    <CreateStream cancelScheduleStream={() => resetAllSearchParamsExcept(["type"])}/>
                )}
                {(!streamKeyParam && !isScheduleNewStream) && emptyEditPage}
            </Page>
        </DualPage>
    );
}